import { lbUid, useMiscStore } from "@lb/frontend";
import {
  VARIABLE_TYPES,
  WORKPAPER_CONDITION_ACTIONS,
  depopulateScripts,
  depopulateRules,
} from "@lb/utils";
import { useFormik, useFormikContext } from "formik";
import { compact, find, fromPairs, map } from "lodash";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useOrgData, useVariables } from "../store";

const engine =
  (Component) =>
  ({ ...props }) => {
    const { values } = useFormikContext();
    const { misc, storeMisc } = useMiscStore();
    const { data } = props;
    const [testDataDialog, setTestDataDialog] = useState(false);
    const WORKPAPER_MISC_ID = `${data._id}-workpaper-test-data`;
    const { orgData } = useOrgData();
    const varList = useVariables((store) => store.variables);

    const variables = useMemo(() => {
      return map(varList, (variable) => ({
        ...variable,
        type: variable?.type?.value || variable?.type,
      }));
    }, [varList]);

    const workflows = useMemo(() => {
      return map(values.workflows, (workflow) => ({
        actions: map(workflow.actions, ({ action, payload, target }) => {
          let selectAction = action?.value || action,
            targetVariable = find(variables, {
              name: target?.name || target,
            });
          return {
            action: selectAction,
            payload:
              {
                [WORKPAPER_CONDITION_ACTIONS.key.SET_VALUE]:
                  {
                    [VARIABLE_TYPES.key.MCQ]: targetVariable?.multiple
                      ? compact(map(payload, "value") || [])
                      : payload.value || payload,
                  }[targetVariable?.type] || payload,
                [WORKPAPER_CONDITION_ACTIONS.key.SET_OPTIONS]: compact(map(payload, "value")),
              }[selectAction] || payload,
            target: targetVariable?.name,
          };
        }),
        altActions: map(workflow.altActions, ({ action, payload, target }) => {
          let selectAction = action?.value || action,
            targetVariable = find(variables, {
              name: target?.name,
            });
          return {
            action: selectAction,
            payload:
              {
                [WORKPAPER_CONDITION_ACTIONS.key.SET_VALUE]:
                  {
                    [VARIABLE_TYPES.key.MCQ]: targetVariable?.multiple
                      ? compact(map(payload, "value")) || []
                      : payload.value || payload,
                  }[targetVariable?.type] || payload,
                [WORKPAPER_CONDITION_ACTIONS.key.SET_OPTIONS]: compact(map(payload, "value")),
              }[selectAction] || payload,
            target: targetVariable?.name,
          };
        }),
        conjunction: workflow.conjunction,
        title: workflow.title,
        rules: depopulateRules(workflow.rules, variables),
      }));
    }, [values.workflows, variables]);

    const manuscript = useMemo(
      () => compact(map(values.manuscript, (s) => depopulateScripts(s))),
      [values.manuscript]
    );

    const initialWorkpaperValues = useMemo(
      () => ({
        ...fromPairs(
          map(variables, (v) => {
            if (v.type === VARIABLE_TYPES.key.STATIC) {
              return [v.name, v.value];
            }

            return [v.name, misc[WORKPAPER_MISC_ID]?.[v.name]];
          })
        ),
        contract: {
          name: misc[WORKPAPER_MISC_ID]?.contract?.name ?? "Untitled",
          code: misc[WORKPAPER_MISC_ID]?.contract?.code ?? lbUid(),
          vendors: misc[WORKPAPER_MISC_ID]?.contract?.vendors ?? [],
          duration: {
            years: misc[WORKPAPER_MISC_ID]?.contract?.duration?.years ?? 1,
            months: misc[WORKPAPER_MISC_ID]?.contract?.duration?.months ?? 0,
            days: misc[WORKPAPER_MISC_ID]?.contract?.duration?.days ?? 0,
          },
          timeline: {
            effective: misc[WORKPAPER_MISC_ID]?.contract?.timeline?.effective ?? new Date(),
            execution: misc[WORKPAPER_MISC_ID]?.contract?.timeline?.execution ?? new Date(),
            expiry:
              misc[WORKPAPER_MISC_ID]?.contract?.timeline?.expiry ??
              moment(new Date()).add({ years: 1 }).toDate(),
          },
        },
        organization: {
          ...(misc[WORKPAPER_MISC_ID]?.organization ?? orgData),
          signatories: misc[WORKPAPER_MISC_ID]?.organization?.signatories ?? [
            {
              name: "Untitled",
              email: "example@signatory.com",
              designation: "Untitled",
            },
          ],
        },
      }),
      [WORKPAPER_MISC_ID, misc, orgData, variables]
    );

    const contractFormik = useFormik({
      validateOnChange: false,
      initialValues: {
        workpaper: initialWorkpaperValues,
      },
      onSubmit: (values, { setSubmitting }) => {
        storeMisc({ [WORKPAPER_MISC_ID]: values.workpaper });
        setSubmitting(false);
      },
      enableReinitialize: true,
    });

    return (
      <Component
        {...props}
        {...{
          testDataDialog,
          setTestDataDialog,
          contractFormik,
          workflows,
          variables,
          manuscript,
          values,
        }}
      />
    );
  };

export default engine;
