import { useFormik } from "formik";

const engine =
  (Component) =>
  ({ ...props }) => {
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        organization: "",
        template: "",
      },
    });

    return <Component {...props} {...{ formik }} />;
  };

export default engine;
