import { useMiscStore } from "@lb/frontend";
import { useFormik } from "formik";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useOrgData } from "../../store";

const engine =
  (Component) =>
  ({ ...props }) => {
    const { misc, storeMisc } = useMiscStore();
    const { templateId } = useParams();
    const { orgData } = useOrgData();
    const { handleSubmit, handleClose, initialValues } = props;

    const tab = useMemo(
      () => ({
        selected: misc[`${templateId}-test-data-tab`] ?? "organization",
        changeTab: (newTabValue) => storeMisc({ [`${templateId}-test-data-tab`]: newTabValue }),
      }),
      [misc, storeMisc, templateId]
    );

    const formik = useFormik({
      enableReinitialize: true,
      initialValues,
      onSubmit: (values, { setSubmitting }) => {
        setSubmitting(false);
        handleSubmit(values);
        handleClose();
      },
    });

    const setCollapsed = (name) => {
      name = `${templateId}-test-data-${name}`;
      storeMisc({ [name]: !Boolean(misc[name]) });
    };

    const isCollapsed = (name) => misc[`${templateId}-test-data-${name}`];

    return <Component {...props} {...{ formik, setCollapsed, isCollapsed, orgData, tab }} />;
  };

export default engine;
