import { Box, Toolbar } from "@mui/material";
import React from "react";
import engine from "./engine";
import WorkPage from "./WorkPage";

function Test({ variables, workflows }) {
  return (
    <Box>
      <WorkPage variables={variables} workflows={workflows} />
      <Toolbar />
    </Box>
  );
}

export default engine(Test);
