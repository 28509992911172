import { LbSmartSelect } from "@lb/frontend";
import { EDITOR_NODE_TYPES, getNodeType, VARIABLE_TYPES } from "@lb/utils";
import { useFormikContext } from "formik";
import { get, isEmpty, join, size, slice, split, times } from "lodash";
import { memo, useMemo } from "react";
import { useVariables } from "../../store";
import {
  organizationSignatoryVariables,
  vendorSignatoryVariables,
  vendorVariables,
} from "../../engine";

const VariableStructure = memo(
  ({ name, nodeData, index, arrayHelpers, parentAttributes, parentNodeType }) => {
    const { values } = useFormikContext();
    const variables = useVariables((store) => store.variables);

    const options = useMemo(() => {
      let variablesList = [];

      let nodePathArr = split(name, ".");
      times(size(nodePathArr), (pathIndex) => {
        let nestedNodePath = slice(nodePathArr, 0, size(nodePathArr) - pathIndex);
        let nestedNode = get(values, join(nestedNodePath, "."));
        let nestedNodeType = getNodeType(nestedNode);

        if (nestedNodeType === EDITOR_NODE_TYPES.key.LOOP) {
          switch (nestedNode?.source?.name) {
            case "contract.vendors":
              variablesList.push(...vendorVariables);
              break;

            case "organization.signatories":
              variablesList.push(...organizationSignatoryVariables);
              break;

            case "contract.vendors[n].signatories":
              variablesList.push(...vendorSignatoryVariables);
              break;

            default:
              variablesList.push({
                label: `${nestedNode?.source?.label} [nth]`,
                name: `${nestedNode?.source?.name}[n]`,
                type: VARIABLE_TYPES.key.TEXT,
              });
              break;
          }
        }
      });

      if (!isEmpty(variables)) variablesList.push(...variables);

      return variablesList;
    }, [name, variables, values]);

    return (
      <LbSmartSelect
        required
        valueKey="name"
        name={`${name}.variable`}
        options={options}
        placeholder="Select the variable"
        inputProps={{
          sx: {
            "& .MuiInputBase-root": {
              backgroundColor: "beige",
              fontWeight: nodeData.bold ? "bolder" : "normal",
              fontStyle: nodeData.italics ? "italic" : "normal",
              textDecoration: join(nodeData.decoration, " "),
              "& fieldset": {
                border: 0,
              },
              "& input": {
                textAlign: nodeData.alignment,
                fontWeight: nodeData.bold ? "bolder" : "normal",
                fontStyle: nodeData.italics ? "italic" : "normal",
                textDecoration: join(nodeData.decoration, " "),
              },
            },
          },
        }}
      />
    );
  }
);

export default VariableStructure;
