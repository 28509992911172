import {
  contractsTemplatesEndpoints,
  getRequest,
  LbLoading,
  lbUid,
  orgEndpoints,
  postRequest,
  putRequest,
  useReactQuery,
  useSearchParameters,
} from "@lb/frontend";
import {
  BANK_ACCOUNT_TYPES,
  BUSINESS_CATEGORIES,
  BUSINESS_TYPES,
  EDITOR_NODE_TYPES,
  generateOptionVariants,
  getNodeType,
  GOVERNMENT_IDS,
  INDUSTRY_OPTIONS,
  masterClientUrls,
  OPERATORS,
  ORG_TYPES,
  PAYMENT_TERMS,
  RULE_CONJUNCTIONS,
  TDS_SECTION_OPTIONS,
  VARIABLE_TYPES,
  VENDOR_TYPE_OPTIONS,
  WORKPAPER_CONDITION_ACTIONS,
} from "@lb/utils";
import { useFormik } from "formik";
import {
  compact,
  find,
  findIndex,
  forEach,
  includes,
  isArray,
  isEmpty,
  isObject,
  map,
} from "lodash";
import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useOrgData, useVariables } from "./store";

export const organizationSignatoryVariables = [
  {
    label: "Organization Signatory [nth] Name",
    name: "organization.signatories[n].name",
    type: VARIABLE_TYPES.key.TEXT,
  },
  {
    label: "Organization Signatory [nth] Email",
    name: "organization.signatories[n].email",
    type: VARIABLE_TYPES.key.TEXT,
  },
  {
    label: "Organization Signatory [nth] Designation",
    name: "organization.signatories[n].designation",
    type: VARIABLE_TYPES.key.TEXT,
  },
];

export const vendorSignatoryVariables = [
  {
    label: "Vendor [nth] Signatory [nth] Name",
    name: "contract.vendors[n].signatories[n].name",
    type: VARIABLE_TYPES.key.TEXT,
  },
  {
    label: "Vendor [nth] Signatory [nth] Email",
    name: "contract.vendors[n].signatories[n].email",
    type: VARIABLE_TYPES.key.TEXT,
  },
  {
    label: "Vendor [nth] Signatory [nth] Designation",
    name: "contract.vendors[n].signatories[n].designation",
    type: VARIABLE_TYPES.key.TEXT,
  },
];

export const vendorVariables = [
  {
    label: "Vendor [nth] Name",
    name: "contract.vendors[n].name",
    type: VARIABLE_TYPES.key.TEXT,
  },
  {
    label: "Vendor [nth] Type",
    name: "contract.vendors[n].type",
    type: VARIABLE_TYPES.key.MCQ,
    multiple: false,
    options: VENDOR_TYPE_OPTIONS.keys,
  },
  {
    label: "Vendor [nth] Father Name",
    name: "contract.vendors[n].fatherName",
    type: VARIABLE_TYPES.key.TEXT,
  },
  {
    label: "Vendor [nth] Gender",
    name: "contract.vendors[n].gender",
    type: VARIABLE_TYPES.key.TEXT,
  },
  {
    label: "Vendor [nth] Abbreviation",
    name: "contract.vendors[n].abbreviation",
    type: VARIABLE_TYPES.key.TEXT,
  },
  {
    label: "Vendor [nth] Email Address",
    name: "contract.vendors[n].email",
    type: VARIABLE_TYPES.key.TEXT,
  },
  {
    label: "Vendor [nth] Contact No",
    name: "contract.vendors[n].contactNo",
    type: VARIABLE_TYPES.key.TEXT,
  },
  {
    label: "Vendor [nth] Annual Turnover",
    name: "contract.vendors[n].turnover",
    type: VARIABLE_TYPES.key.NUMBER,
  },
  {
    label: "Vendor [nth] Org Type",
    name: "contract.vendors[n].orgType",
    type: VARIABLE_TYPES.key.MCQ,
    multiple: false,
    options: ORG_TYPES.keys,
  },
  {
    label: "Vendor [nth] Business Type",
    name: "contract.vendors[n].businessType",
    type: VARIABLE_TYPES.key.MCQ,
    multiple: false,
    options: BUSINESS_TYPES.keys,
  },
  {
    label: "Vendor [nth] Business Category",
    name: "contract.vendors[n].businessCategory",
    type: VARIABLE_TYPES.key.MCQ,
    multiple: false,
    options: BUSINESS_CATEGORIES.keys,
  },
  {
    label: "Vendor [nth] Industry",
    name: "contract.vendors[n].industry",
    type: VARIABLE_TYPES.key.MCQ,
    multiple: false,
    options: map(INDUSTRY_OPTIONS.options, "value"),
  },
  {
    label: "Vendor [nth] Address",
    name: "contract.vendors[n].location.address.name",
    type: VARIABLE_TYPES.key.TEXT,
  },
  {
    label: "Vendor [nth] Country Name",
    name: "contract.vendors[n].location.country.name",
    type: VARIABLE_TYPES.key.TEXT,
  },
  {
    label: "Vendor [nth] Country Code",
    name: "contract.vendors[n].location.country.code",
    type: VARIABLE_TYPES.key.TEXT,
  },
  {
    label: "Vendor [nth] State",
    name: "contract.vendors[n].location.state",
    type: VARIABLE_TYPES.key.TEXT,
  },
  {
    label: "Vendor [nth] Postal Code",
    name: "contract.vendors[n].location.postalCode",
    type: VARIABLE_TYPES.key.TEXT,
  },
  {
    label: "Vendor [nth] TDS Section",
    name: "contract.vendors[n].tdsSection",
    type: VARIABLE_TYPES.key.MCQ,
    multiple: false,
    options: TDS_SECTION_OPTIONS.keys,
  },
  ...map(GOVERNMENT_IDS.forVendor().keys, (governmentId) => ({
    label: `Vendor [nth] ${GOVERNMENT_IDS._[governmentId]}`,
    name: `contract.vendors[n].governmentId.${governmentId}`,
    type: VARIABLE_TYPES.key.TEXT,
  })),
  {
    label: "Vendor [nth] Turnover",
    name: "contract.vendors[n].turnover",
    type: VARIABLE_TYPES.key.TEXT,
  },
  {
    label: "Vendor [nth] Payment Terms",
    name: "contract.vendors[n].paymentTerms",
    type: VARIABLE_TYPES.key.MCQ,
    multiple: false,
    options: PAYMENT_TERMS.keys,
  },
  {
    label: "Vendor [nth] Credit Period",
    name: "contract.vendors[n].creditPeriod",
    type: VARIABLE_TYPES.key.TEXT,
  },
  {
    label: "Vendor [nth] Credit Limit",
    name: "contract.vendors[n].creditLimit",
    type: VARIABLE_TYPES.key.TEXT,
  },
  {
    label: "Vendor [nth] Bank Account Type",
    name: "contract.vendors[n].bank.accountType",
    type: VARIABLE_TYPES.key.MCQ,
    multiple: false,
    options: BANK_ACCOUNT_TYPES.keys,
  },
  {
    label: "Vendor [nth] Bank Account Number",
    name: "contract.vendors[n].bank.accountNumber",
    type: VARIABLE_TYPES.key.TEXT,
  },
  {
    label: "Vendor [nth] Bank Account Name",
    name: "contract.vendors[n].bank.accountName",
    type: VARIABLE_TYPES.key.TEXT,
  },
  {
    label: "Vendor [nth] Bank IFSC",
    name: "contract.vendors[n].bank.ifsc",
    type: VARIABLE_TYPES.key.TEXT,
  },
  {
    label: "Vendor [nth] Signatories",
    name: "contract.vendors[n].signatories",
    type: VARIABLE_TYPES.key.MCQ,
    multiple: true,
  },
  {
    label: "Number of Vendor [nth] Signatories",
    name: "contract.vendors[n].signatories.length",
    type: VARIABLE_TYPES.key.NUMBER,
  },
];

const STEP_OPTIONS = generateOptionVariants({
  metadata: "Metadata",
  variables: "Variables",
  workpaper: "Workpaper",
  header: "Header",
  footer: "Footer",
  manuscript: "Manuscript",
  transcript: "Transcript",
});

function flattenObject(obj, currentPath = "", result = {}) {
  forEach(obj, (value, key) => {
    const newPath = currentPath ? `${currentPath}.${key}` : key;
    if (isObject(value) && !isEmpty(value)) {
      flattenObject(value, newPath, result);
    } else {
      result[newPath] = value;
    }
  });
  return result;
}

const ruleSchema = Yup.object().shape({
  source: Yup.mixed().required("Required"),
  operator: Yup.mixed().required("Required"),
  value: Yup.mixed().required("Required"),
});

const ruleGroupSchema = Yup.array().of(
  Yup.lazy((obj) => {
    if (obj && obj.conjunction) {
      return Yup.object().shape({
        conjunction: Yup.string().oneOf(["and", "or"], "Invalid conjunction").required("Required"),
        rules: Yup.array().of(ruleSchema).required("Required"),
      });
    }
    return ruleSchema;
  })
);

const engine =
  (Component) =>
  ({ ...props }) => {
    const navigate = useNavigate();
    const { templateId, orgId } = useParams();
    const { setOrgData } = useOrgData();
    const { searchParams, setSearchParams } = useSearchParameters();
    const { storeVariables } = useVariables();
    const { orgData } = useOrgData();
    const step = {
      selected: useMemo(
        () =>
          includes(STEP_OPTIONS.keys, searchParams.step) ? searchParams.step : STEP_OPTIONS.keys[0],
        [searchParams.step]
      ),
      select: (v) => setSearchParams({ ...searchParams, step: v }),
    };

    const nextStep = () => {
      const currentIndex = findIndex(STEP_OPTIONS.keys, step.selected);
      step.select(STEP_OPTIONS.keys[currentIndex + 1]);
    };

    const previousStep = () => {
      const currentIndex = findIndex(STEP_OPTIONS.keys, step.selected);
      step.select(STEP_OPTIONS.keys[currentIndex - 1]);
    };

    const handleClose = () => {
      navigate(masterClientUrls.clm.templates.base);
    };

    const {
      data: { data = {} } = {},
      isLoading,
      refetch,
    } = useReactQuery({
      queryKeys: ["templates", templateId, orgId],
      apiCall: () =>
        getRequest({
          url: contractsTemplatesEndpoints.getById(templateId, orgId),
          baseUrl: "clm",
        }),
      options: {
        enabled: Boolean(templateId),
        cacheTime: 0,
      },
    });

    useReactQuery({
      queryKeys: ["organization", orgId],
      apiCall: () =>
        getRequest({
          url: orgEndpoints.getById(orgId, "all").base + "/all",
          baseUrl: "master",
        }),
      options: {
        enabled: Boolean(orgId !== "public"),
        onSuccess: ({ data: orgResult }) => {
          setOrgData(orgResult);
        },
      },
    });

    const organizationVariables = useMemo(
      () => [
        {
          label: "Organization Name",
          name: "organization.name",
          type: VARIABLE_TYPES.key.TEXT,
        },
        {
          label: "Organization Abbreviation",
          name: "organization.abbreviation",
          type: VARIABLE_TYPES.key.TEXT,
        },
        {
          label: "Organization Email Address",
          name: "organization.email",
          type: VARIABLE_TYPES.key.TEXT,
        },
        {
          label: "Organization Contact No",
          name: "organization.contactNo",
          type: VARIABLE_TYPES.key.TEXT,
        },
        {
          label: "Organization Org Type",
          name: "organization.orgType",
          type: VARIABLE_TYPES.key.MCQ,
          multiple: false,
          options: ORG_TYPES.keys,
        },
        {
          label: "Organization Business Type",
          name: "organization.businessType",
          type: VARIABLE_TYPES.key.MCQ,
          multiple: false,
          options: BUSINESS_TYPES.keys,
        },
        {
          label: "Organization Business Category",
          name: "organization.businessCategory",
          type: VARIABLE_TYPES.key.MCQ,
          multiple: false,
          options: BUSINESS_CATEGORIES.keys,
        },
        {
          label: "Organization Industry",
          name: "organization.industry",
          type: VARIABLE_TYPES.key.MCQ,
          multiple: false,
          options: INDUSTRY_OPTIONS.keys,
        },
        {
          label: "Organization Address",
          name: "organization.location.address.name",
          type: VARIABLE_TYPES.key.TEXT,
        },
        {
          label: "Organization Country Name",
          name: "organization.location.country.name",
          type: VARIABLE_TYPES.key.TEXT,
        },
        {
          label: "Organization Country Code",
          name: "organization.location.country.code",
          type: VARIABLE_TYPES.key.TEXT,
        },
        {
          label: "Organization State",
          name: "organization.location.state",
          type: VARIABLE_TYPES.key.TEXT,
        },
        {
          label: "Organization Postal Code",
          name: "organization.location.postalCode",
          type: VARIABLE_TYPES.key.TEXT,
        },
        {
          label: "Organization Website",
          name: "organization.website",
          type: VARIABLE_TYPES.key.TEXT,
        },
        {
          label: "Organization Annual Turnover",
          name: "organization.turnover",
          type: VARIABLE_TYPES.key.NUMBER,
        },
        {
          label: "Organization TDS Section",
          name: "organization.tdsSection",
          type: VARIABLE_TYPES.key.MCQ,
          multiple: false,
          options: TDS_SECTION_OPTIONS.keys,
        },
        {
          label: "Organization Signatories",
          name: "organization.signatories",
          type: VARIABLE_TYPES.key.MCQ,
          multiple: true,
        },
        {
          label: "Number of Organization Signatories",
          name: "organization.signatories.length",
          type: VARIABLE_TYPES.key.NUMBER,
        },
      ],
      []
    );

    const contractVariables = useMemo(
      () => [
        {
          label: "Contract Name",
          name: "contract.name",
          type: VARIABLE_TYPES.key.TEXT,
        },
        {
          label: "Contract Code",
          name: "contract.code",
          type: VARIABLE_TYPES.key.TEXT,
        },
        {
          label: "Contract Effective Date",
          name: "contract.timeline.effective",
          type: VARIABLE_TYPES.key.DATE,
          dateFormat: orgData.dateFormat,
        },
        {
          label: "Contract Execution Date",
          name: "contract.timeline.execution",
          type: VARIABLE_TYPES.key.DATE,
          dateFormat: orgData.dateFormat,
        },
        {
          label: "Contract Expiry Date",
          name: "contract.timeline.expiry",
          type: VARIABLE_TYPES.key.DATE,
          dateFormat: orgData.dateFormat,
        },
        {
          label: "Contract Creation Date",
          name: "contract.timeline.createdAt",
          type: VARIABLE_TYPES.key.DATE,
          dateFormat: orgData.dateFormat,
        },
        {
          label: "Contract Duration Years",
          name: "contract.duration.years",
          type: VARIABLE_TYPES.key.NUMBER,
        },
        {
          label: "Contract Duration Months",
          name: "contract.duration.months",
          type: VARIABLE_TYPES.key.NUMBER,
        },
        {
          label: "Contract Duration Days",
          name: "contract.duration.days",
          type: VARIABLE_TYPES.key.NUMBER,
        },
        {
          label: "Contract Vendors",
          name: "contract.vendors",
          type: VARIABLE_TYPES.key.MCQ,
          multiple: true,
        },
        {
          label: "Number of Vendors",
          name: "contract.vendors.length",
          type: VARIABLE_TYPES.key.NUMBER,
        },
      ],
      [orgData.dateFormat, vendorVariables]
    );

    const initialValues = useMemo(() => {
      const populateRules = (rulesList) => {
        return map(rulesList, ({ source, value, operator, conjunction, rules }) => {
          let srcVar = find(
            [
              ...data?.variables,
              ...contractVariables,
              ...organizationVariables,
              ...vendorVariables,
              ...vendorSignatoryVariables,
              ...organizationSignatoryVariables,
            ],
            { name: source?.name || source }
          );

          if (isEmpty(rules)) {
            let r = {
              source: srcVar,
              value,
              operator: { label: OPERATORS.all._[operator], value: operator },
            };

            if (srcVar?.type === VARIABLE_TYPES.key.MCQ && srcVar?.multiple)
              r.value = map(value, (v) => ({ label: v, value: v }));

            if (srcVar?.type === VARIABLE_TYPES.key.MCQ && !srcVar?.multiple)
              switch (operator) {
                case OPERATORS.all.key.EQUALS:
                  r.value = { label: value, value };
                  break;
                case OPERATORS.all.key.NOT_EQUALS:
                  r.value = { label: value, value };
                  break;
                case OPERATORS.all.key.IN:
                  r.value = map(value, (v) => ({ label: v, value: v }));
                  break;
                case OPERATORS.all.key.NOT_IN:
                  r.value = map(value, (v) => ({ label: v, value: v }));
                  break;
                default:
                  break;
              }

            return r;
          } else
            return {
              conjunction,
              rules: populateRules(rules),
            };
        });
      };

      const populateNode = (script) => {
        if (isEmpty(script)) return null;

        const nodeId = script.id || lbUid();
        const nodeType = getNodeType(script);

        switch (nodeType) {
          case EDITOR_NODE_TYPES.key.TEXT:
            return {
              ...script,
              id: nodeId,
              text: script.text,
              alignment: script.alignment || "justify",
              bold: script.bold ?? false,
              italics: script.italics ?? false,
              decoration:
                isArray(script.decoration) && !isEmpty(script.decoration) ? script.decoration : [],
              fontSize: script.fontSize || 14,
            };
          case EDITOR_NODE_TYPES.key.PARAGRAPH:
            return {
              ...script,
              id: nodeId,
              text: compact(map(script.text, (i) => populateNode(i))),
              alignment: script.alignment || "justify",
              bold: script.bold ?? false,
              italics: script.italics ?? false,
              decoration:
                isArray(script.decoration) && !isEmpty(script.decoration) ? script.decoration : [],
              fontSize: script.fontSize || 14,
            };
          case EDITOR_NODE_TYPES.key.OL:
            return {
              ...script,
              id: nodeId,
              ol: compact(map(script.ol, (i) => populateNode(i))),
              type: script.type || "decimal",
              bold: script.bold ?? false,
              italics: script.italics ?? false,
              decoration:
                isArray(script.decoration) && !isEmpty(script.decoration) ? script.decoration : [],
              fontSize: script.fontSize || 14,
            };
          case EDITOR_NODE_TYPES.key.UL:
            return {
              ...script,
              id: nodeId,
              ul: compact(map(script.ul, (i) => populateNode(i))),
              type: script.type || "disc",
              bold: script.bold ?? false,
              italics: script.italics ?? false,
              decoration:
                isArray(script.decoration) && !isEmpty(script.decoration) ? script.decoration : [],
              fontSize: script.fontSize || 14,
            };

          case EDITOR_NODE_TYPES.key.SPACE:
            return {
              ...script,
              id: nodeId,
              text: script.text || "\n",
            };
          case EDITOR_NODE_TYPES.key.COLUMNS:
            return {
              ...script,
              id: nodeId,
              columns: compact(map(script.columns, (i) => populateNode(i))),
              alignment: script.alignment || "justify",
              bold: script.bold ?? false,
              italics: script.italics ?? false,
              decoration:
                isArray(script.decoration) && !isEmpty(script.decoration) ? script.decoration : [],
              fontSize: script.fontSize || 14,
            };
          case EDITOR_NODE_TYPES.key.STACK:
            return {
              ...script,
              id: nodeId,
              stack: compact(map(script.stack, (i) => populateNode(i))),
              alignment: script.alignment || "justify",
              bold: script.bold ?? false,
              italics: script.italics ?? false,
              decoration:
                isArray(script.decoration) && !isEmpty(script.decoration) ? script.decoration : [],
              fontSize: script.fontSize || 14,
            };
          case EDITOR_NODE_TYPES.key.VARIABLE:
            let variable = {};

            variable = find(
              [
                ...data?.variables,
                ...contractVariables,
                ...organizationVariables,
                ...vendorVariables,
                ...vendorSignatoryVariables,
                ...organizationSignatoryVariables,
              ],
              {
                name: script.variable,
              }
            );

            if (isEmpty(variable)) {
              if (includes(script.variable, "[n]")) {
                let parentVarName = script.variable?.match(/^[^[]+/)[0];
                let parentVar = find(
                  [
                    ...data?.variables,
                    ...vendorVariables,
                    ...organizationVariables,
                    ...contractVariables,
                    ...vendorSignatoryVariables,
                    ...organizationSignatoryVariables,
                  ],
                  { name: parentVarName }
                );

                variable = {
                  label: `${parentVar?.label} [nth]`,
                  name: script.variable,
                  type: VARIABLE_TYPES.key.TEXT,
                };
              }
            }

            return {
              ...script,
              id: nodeId,
              variable,
              alignment: script.alignment || "justify",
              ...(script.type ? { type: script.type } : {}),
              bold: script.bold ?? false,
              italics: script.italics ?? false,
              decoration:
                isArray(script.decoration) && !isEmpty(script.decoration) ? script.decoration : [],
              fontSize: script.fontSize || 14,
            };
          case EDITOR_NODE_TYPES.key.DECISION:
            return {
              ...script,
              id: nodeId,
              rules: populateRules(script.rules),
              then: compact(map(script.then, (i) => populateNode(i))),
              else: compact(map(script.else, (i) => populateNode(i))),
            };
          case EDITOR_NODE_TYPES.key.LOOP:
            let srcVar = find(
              [
                ...data?.variables,
                ...contractVariables,
                ...organizationVariables,
                ...vendorVariables,
                ...vendorSignatoryVariables,
                ...organizationSignatoryVariables,
              ],
              { name: script?.source?.name || script?.source }
            );

            return {
              ...script,
              source: srcVar,
              id: nodeId,
              loop: compact(map(script.loop, (i) => populateNode(i))),
              conjunction: compact(map(script.conjunction, (i) => populateNode(i))),
            };
          default:
            break;
        }

        return script;
      };

      return {
        name: data?.name || "",
        description: data?.description || "",
        content: data?.content || "",
        abbreviation: data?.abbreviation || "",
        variables: map(data?.variables, (variable) => ({
          ...variable,
          label: variable?.label || "",
          description: variable?.description || "",
          selectAll: variable?.selectAll || "",
          name: variable?.name || "",
          multiple: variable?.multiple ?? false,
          options: isArray(variable?.options) ? variable?.options : [],
          value: variable?.value ?? "",
          type: {
            label: VARIABLE_TYPES._[variable?.type],
            value: variable?.type,
          },
          min: variable?.min ?? "",
          max: variable?.max ?? "",
          placeholder: variable?.placeholder || "",
        })),
        workflows:
          map(data.workflows, (workflow) => ({
            conjunction: workflow.conjunction,
            title: workflow.title,
            rules: populateRules(workflow.rules),
            actions: map(workflow.actions, ({ action, payload, target }) => {
              let targetVariable = find(data.variables, { name: target });
              return {
                action: {
                  label: WORKPAPER_CONDITION_ACTIONS._[action],
                  value: action,
                },
                payload:
                  {
                    [WORKPAPER_CONDITION_ACTIONS.key.SET_VALUE]: {
                      [VARIABLE_TYPES.key.MCQ]: targetVariable?.multiple
                        ? compact(
                            map(payload, (v) => ({
                              label: v === "*" ? "Select All (*)" : v,
                              value: v,
                            })) || []
                          )
                        : { label: payload, value: payload },
                    }[targetVariable?.type],
                    [WORKPAPER_CONDITION_ACTIONS.key.SET_OPTIONS]: compact(
                      map(payload, (v) => ({
                        label: v === "*" ? "Select All (*)" : v,
                        value: v,
                      })) || []
                    ),
                  }[action] || payload,
                target: find(data.variables, { name: target }),
              };
            }),
            altActions: map(workflow.altActions, ({ action, payload, target }) => {
              let targetVariable = find(data.variables, { name: target });
              return {
                action: {
                  label: WORKPAPER_CONDITION_ACTIONS._[action],
                  value: action,
                },
                payload:
                  {
                    [WORKPAPER_CONDITION_ACTIONS.key.SET_VALUE]: {
                      [VARIABLE_TYPES.key.MCQ]: targetVariable?.multiple
                        ? compact(
                            map(payload, (v) => ({
                              label: v === "*" ? "Select All (*)" : v,
                              value: v,
                            })) || []
                          )
                        : { label: payload, value: payload },
                    }[targetVariable?.type],
                    [WORKPAPER_CONDITION_ACTIONS.key.SET_OPTIONS]: compact(
                      map(payload, (v) => ({
                        label: v === "*" ? "Select All (*)" : v,
                        value: v,
                      })) || []
                    ),
                  }[action] || payload,
                target: find(data.variables, { name: target }),
              };
            }),
          })) || [],

        pageSize: data?.pageSize || "",
        pageOrientation: data?.pageOrientation || "",
        pageMargins: isEmpty(data?.pageMargins) ? [14, 14, 14, 14] : data?.pageMargins,
        manuscript: isEmpty(data?.manuscript)
          ? []
          : map(data?.manuscript, (script) => populateNode(script)),
      };
    }, [contractVariables, data, organizationVariables]);

    const formik = useFormik({
      validateOnChange: false,
      enableReinitialize: true,
      validationSchema: Yup.object().shape({
        name: Yup.string().required("Name is required"),
        description: Yup.string().notRequired(),
        // content: Yup.string().notRequired(),
        abbreviation: Yup.string().notRequired(),
        variables: Yup.array()
          .of(
            Yup.object().shape(
              {
                label: Yup.string().required("Variable label is required"),
                name: Yup.string().required("Variable name is required"),
                type: Yup.mixed().required("Variable type is required"),
                multiple: Yup.boolean().notRequired(),
                options: Yup.array().when("type", {
                  is: (v) => v?.value === VARIABLE_TYPES.key.MCQ,
                  then: Yup.array()
                    .of(Yup.string())
                    .min(1, "Options are required for MCQ type")
                    .required("Options are required for MCQ type"),
                  otherwise: Yup.array().of(Yup.string()).notRequired(),
                }),
                value: Yup.string().when("type", {
                  is: (v) => v?.value === VARIABLE_TYPES.key.STATIC,
                  then: (schema) => schema.required("Variable value is required"),
                  otherwise: (schema) => schema.notRequired(),
                }),
                min: Yup.number().when(
                  ["type", "multiple", "options", "max"],
                  (type, multiple, options, max, schema) => {
                    if (type?.value === VARIABLE_TYPES.key.NUMBER)
                      return schema.max(max, "Min cannot be greater than max").required("Required");
                    if (type?.value === VARIABLE_TYPES.key.MCQ && multiple)
                      return schema
                        .max(
                          max ?? options?.length,
                          "Min cannot be greater than max/ total number of options"
                        )
                        .notRequired();
                    return Yup.mixed().notRequired();
                  }
                ),
                max: Yup.number().when(
                  ["type", "multiple", "options", "min"],
                  (type, multiple, options, min, schema) => {
                    if (type?.value === VARIABLE_TYPES.key.NUMBER)
                      return schema
                        .min(min ?? 0, "Max cannot be smaller than min")
                        .required("Required");
                    if (type?.value === VARIABLE_TYPES.key.MCQ && multiple)
                      return schema
                        .min(min ?? 0, "Max cannot be smaller than min")
                        .max(
                          options?.length,
                          "Max cannot be greater than the total number of options"
                        )
                        .notRequired();
                    return Yup.mixed().notRequired();
                  }
                ),
              },
              ["min", "max"]
            )
          )
          .notRequired(),
        workflows: Yup.array()
          .of(
            Yup.object().shape({
              title: Yup.string().required("Workflow title is required"),
              conjunction: Yup.string()
                .oneOf(RULE_CONJUNCTIONS.keys, 'Conjunction must be "and" or "or"')
                .required(),
              rules: ruleGroupSchema,
              actions: Yup.array()
                .of(
                  Yup.object().shape({
                    action: Yup.mixed().required("Required"),
                    target: Yup.mixed().required("Required"),
                    payload: Yup.mixed().when(
                      ["action", "target", "$variables"],
                      (action, target, variables, schema) => {
                        if (action?.value === WORKPAPER_CONDITION_ACTIONS.key.SET_OPTIONS) {
                          return Yup.array()
                            .of(
                              Yup.object().shape({ label: Yup.string(), value: Yup.string() }),
                              "Invalid action payload"
                            )
                            .min(1, "Options required for set-options action")
                            .required("Options required for set-options action");
                        }

                        if (action?.value === WORKPAPER_CONDITION_ACTIONS.key.SET_VALUE) {
                          const targetVar = find(variables, { name: target?.name });

                          if (
                            targetVar?.type?.value === VARIABLE_TYPES.key.MCQ &&
                            targetVar?.multiple
                          ) {
                            return Yup.array()
                              .of(
                                Yup.object().shape({
                                  label: Yup.string().required(),
                                  value: Yup.string().required(),
                                })
                              )
                              .min(1, "Variable value is required for set-value action")
                              .required("Variable value is required for set-value action");
                          }

                          if (
                            targetVar?.type?.value === VARIABLE_TYPES.key.MCQ &&
                            !targetVar?.multiple
                          ) {
                            return Yup.object()
                              .shape({
                                label: Yup.string().required(),
                                value: Yup.string().required(),
                              })
                              .required("Variable value is required for set-value action");
                          }

                          return Yup.string().required(
                            "Variable value is required for set-value action"
                          );
                        }

                        return Yup.mixed().notRequired();
                      }
                    ),
                  })
                )
                .min(1)
                .required("At least one action is required"),
              altActions: Yup.array()
                .of(
                  Yup.object().shape({
                    action: Yup.mixed().required("Required"),
                    target: Yup.mixed().required("Required"),
                    payload: Yup.mixed().when(
                      ["action", "target", "$variables"],
                      (action, target, variables, schema) => {
                        if (action?.value === WORKPAPER_CONDITION_ACTIONS.key.SET_OPTIONS) {
                          return Yup.array()
                            .of(
                              Yup.object().shape({ label: Yup.string(), value: Yup.string() }),
                              "Invalid action payload"
                            )
                            .min(1, "Options required for set-options action")
                            .required("Options required for set-options action");
                        }

                        if (action?.value === WORKPAPER_CONDITION_ACTIONS.key.SET_VALUE) {
                          const targetVar = find(variables, { name: target?.name });

                          if (
                            targetVar?.type?.value === VARIABLE_TYPES.key.MCQ &&
                            targetVar?.multiple
                          ) {
                            return Yup.array()
                              .of(
                                Yup.object().shape({
                                  label: Yup.string().required(),
                                  value: Yup.string().required(),
                                })
                              )
                              .min(1, "Variable value is required for set-value action")
                              .required("Variable value is required for set-value action");
                          }

                          if (
                            targetVar?.type?.value === VARIABLE_TYPES.key.MCQ &&
                            !targetVar?.multiple
                          ) {
                            return Yup.object()
                              .shape({
                                label: Yup.string().required(),
                                value: Yup.string().required(),
                              })
                              .required("Variable value is required for set-value action");
                          }

                          return Yup.string().required(
                            "Variable value is required for set-value action"
                          );
                        }

                        return Yup.mixed().notRequired();
                      }
                    ),
                  })
                )
                .notRequired(),
            })
          )
          .notRequired(),
      }),
      initialValues,
      onSubmit: async (values, { setSubmitting }) => {
        try {
          await (templateId ? putRequest : postRequest)({
            url: contractsTemplatesEndpoints.update(templateId, orgId),
            data: values,
            baseUrl: "clm",
          });
          await refetch();
        } catch (error) {
        } finally {
          setSubmitting(false);
        }
      },
    });

    useEffect(() => {
      storeVariables([
        ...formik.values.variables,
        ...contractVariables,
        ...organizationVariables,
        // ...vendorVariables,
        // ...vendorSignatoryVariables,
        // ...organizationSignatoryVariables,
      ]);
    }, [
      contractVariables,
      formik.values.variables,
      orgData.dateFormat,
      organizationVariables,
      storeVariables,
    ]);

    const errors = useMemo(() => flattenObject(formik.errors), [formik.errors]);

    if (isLoading || isEmpty(data)) return <LbLoading py={5} />;

    return (
      <Component
        {...props}
        {...{
          handleClose,
          templateId,
          orgId,
          isLoading,
          data,
          previousStep,
          nextStep,
          step,
          STEP_OPTIONS,
          searchParams,
          setSearchParams,
          formik,
          errors,
        }}
      />
    );
  };

export default engine;
