import { postRequest, useReactQuery } from "@lb/frontend";
import { isEmpty } from "lodash";
import PDFObject from "pdfobject";
import React, { useRef } from "react";

const engine =
  (Component) =>
  ({ ...props }) => {
    const pdfContainerRef = useRef(null);
    const { manuscript, workpaper, variables, pageSize, pageOrientation, pageMargins } = props;

    const { isLoading } = useReactQuery({
      queryKeys: ["template-transcript-doc-pdf", manuscript, workpaper, variables],
      apiCall: () =>
        postRequest({
          url: `templates/create-doc`,
          baseUrl: "clm",
          data: {
            content: manuscript,
            workpaper,
            variables,
            pageSize,
            pageOrientation,
            pageMargins,
          },
          responseType: "blob",
        }),
      options: {
        enabled: !isEmpty(manuscript),
        cacheTime: 0,
        onSuccess: (data) => {
          const blob = new Blob([data], { type: "application/pdf" });
          if (blob.type === "application/pdf") {
            const fileUrl = window.URL.createObjectURL(blob);
            try {
              PDFObject.embed(fileUrl, pdfContainerRef.current, {
                title: "template-pdf",
                pdfOpenParams: { zoom: 0 },
              });
            } catch (error) {
              console.log("🚀 ~ error:", error);
            }
          } else {
            console.error("Failed to generate a valid PDF");
          }
        },
      },
    });

    return <Component {...props} {...{ isLoading, pdfContainerRef }} />;
  };

export default engine;
