import { InputBase } from "@mui/material";
import { Field } from "formik";
import { join } from "lodash";
import { memo } from "react";

const TextStructure = memo(({ name, nodeData }) => {
  return (
    <Field
      as={InputBase}
      name={`${name}.text`}
      multiline
      fullWidth
      sx={{
        borderRadius: "5px",
        backgroundColor: "beige",
        px: 1,
        "& textarea": {
          textAlign: nodeData.alignment,
          fontWeight: nodeData.bold ? "bolder" : "normal",
          fontStyle: nodeData.italics ? "italic" : "normal",
          textDecoration: join(nodeData.decoration, " "),
        },
      }}
    />
  );
});

export default TextStructure;
