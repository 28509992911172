import { LbButton, LbDataTable } from "@lb/frontend";
import { VARIABLE_TYPES } from "@lb/utils";
import { FileCopyOutlined, FunctionsRounded } from "@mui/icons-material";
import { Icon, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { map, snakeCase, upperCase } from "lodash";
import React, { Fragment } from "react";
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import engine from "./engine";

function Variables({
  variables,
  setAddEditDrawer,
  openEditDrawer,
  removeVariable,
  duplicateVariable,
  setImportDrawer,
}) {
  return (
    <Fragment>
      <LbDataTable
        title={
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body1" m={0} p={0}>
              Variables
            </Typography>
            {variables?.length && (
              <Typography
                variant="body2"
                display={"inline-flex"}
                alignItems={"center"}
                fontWeight={"bolder"}
                color="text.secondary"
              >
                (<FunctionsRounded fontSize="inherit" color="primary" />
                <span>{variables?.length}</span>)
              </Typography>
            )}
          </Stack>
        }
        data={map(variables, ({ label, name, type, multiple }) => [
          name,
          label,
          { type, multiple },
          name,
        ])}
        columns={[
          {
            name: "name",
            label: "Name",
            options: {
              customBodyRender: (value) => (
                <Typography variant="body2" noWrap>
                  {value}
                </Typography>
              ),
            },
          },
          {
            name: "label",
            label: "Label",
            options: {
              customBodyRender: (value) => (
                <Typography variant="body2" noWrap>
                  {value}
                </Typography>
              ),
            },
          },
          {
            name: "type",
            label: "Type",
            options: {
              customBodyRender: ({ multiple, type }) => (
                <img
                  alt="Static Badge"
                  src={`https://img.shields.io/badge/${upperCase(
                    snakeCase(type?.label)
                  )}-${upperCase(
                    snakeCase(
                      {
                        [VARIABLE_TYPES.key.TEXT]: "Text Box",
                        [VARIABLE_TYPES.key.MCQ]: multiple ? "Multi Select" : "Single Select",
                        [VARIABLE_TYPES.key.NUMBER]: "Numeric Input",
                        [VARIABLE_TYPES.key.STATIC]: "Just Text",
                        [VARIABLE_TYPES.key.DATE]: "Date Picker",
                      }[type?.value]
                    )
                  )}-${
                    {
                      [VARIABLE_TYPES.key.TEXT]: "blue",
                      [VARIABLE_TYPES.key.MCQ]: multiple ? "red" : "orange",
                      [VARIABLE_TYPES.key.NUMBER]: "teal",
                      [VARIABLE_TYPES.key.STATIC]: "darkviolet",
                      [VARIABLE_TYPES.key.STATIC]: "darkviolet",
                      [VARIABLE_TYPES.key.DATE]: "0077b6",
                    }[type?.value]
                  }?style=flat&labelColor=black`}
                />
              ),
            },
          },
          {
            name: "actions",
            label: "Actions",
            options: {
              customBodyRender: (value, tableMeta) => {
                return (
                  <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                    <Tooltip title="Delete" arrow>
                      <IconButton
                        color="error"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeVariable(value);
                        }}
                      >
                        <Icon fontSize="small">
                          <FiTrash2 />
                        </Icon>
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Duplicate" arrow>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          duplicateVariable(value);
                        }}
                      >
                        <FileCopyOutlined fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Edit" arrow>
                      <IconButton onClick={() => openEditDrawer(value)}>
                        <Icon fontSize="small">
                          <FiEdit3 />
                        </Icon>
                      </IconButton>
                    </Tooltip>
                  </Stack>
                );
              },
              setCellProps: (value) => {
                return {
                  align: "center",
                  padding: "none",
                };
              },
              setCellHeaderProps: (value) => {
                return {
                  align: "center",
                };
              },
            },
          },
        ]}
        options={{
          pagination: false,
          filter: false,
          customToolbar: ({ displayData }) => {
            return (
              <Fragment>
                <LbButton sx={{ order: -1 }} variant="outlined">
                  Workflows
                </LbButton>
                <LbButton
                  sx={{ order: -1 }}
                  variant="outlined"
                  onClick={() => setImportDrawer(true)}
                >
                  Import
                </LbButton>
                <LbButton
                  sx={{ order: -1 }}
                  variant="outlined"
                  onClick={() => setAddEditDrawer(true)}
                >
                  Create
                </LbButton>
              </Fragment>
            );
          },
        }}
      />
    </Fragment>
  );
}

export default engine(Variables);
