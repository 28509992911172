import { create } from "zustand";

export const useOrgData = create((set) => ({
  orgData: {},
  setOrgData: (payload) => set({ orgData: payload }),
}));

export const useVariables = create((set) => ({
  variables: [],
  storeVariables: (payload) => set({ variables: payload }),
}));
