import { Children, Fragment, useState } from "react";
import ImportTemplate from "./ImportTemplate";
import { SpeedDial, SpeedDialAction, SpeedDialIcon, Typography } from "@mui/material";
import { AssignmentReturnOutlined, LibraryBooksOutlined } from "@mui/icons-material";

const engine =
  (Component) =>
  ({ ...props }) => {
    const [importDrawer, setImportDrawer] = useState(false);
    return (
      <Fragment>
        <ImportTemplate open={importDrawer} handleClose={() => setImportDrawer(false)} />

        <Component {...props} {...{}} />

        <SpeedDial
          ariaLabel="SpeedDial tooltip example"
          sx={{ position: "fixed", bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
        >
          {Children.toArray(
            [
              {
                icon: <AssignmentReturnOutlined fontSize="small" />,
                name: <Typography variant="body2">Import&nbsp;Template</Typography>,
                onClick: () => setImportDrawer(true),
              },
              {
                icon: <LibraryBooksOutlined fontSize="small" />,
                name: <Typography variant="body2">Content&nbsp;Library</Typography>,
              },
            ].map((action) => (
              <SpeedDialAction
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipOpen
                onClick={action.onClick}
              />
            ))
          )}
        </SpeedDial>
      </Fragment>
    );
  };

export default engine;
