import {
  LbActionFooter,
  LbButton,
  LbNumberField,
  LbSmartSelect,
  LbSwitch,
  LbTags,
  LbTextArea,
  LbTextField,
  LbTitle,
} from "@lb/frontend";
import { VARIABLE_TYPES } from "@lb/utils";
import { Close } from "@mui/icons-material";
import { AppBar, Drawer, Stack, Toolbar } from "@mui/material";
import { FormikProvider } from "formik";
import { includes } from "lodash";
import React, { Fragment } from "react";
import engine from "./engine";

function AddEditVariable({ formik, open, handleClose, data }) {
  return (
    <Fragment>
      <Drawer
        open={open}
        anchor="right"
        onClose={handleClose}
        PaperProps={{ sx: { width: { xs: "100%", md: "50%", lg: "35%" } } }}
      >
        <Stack height="100%">
          <AppBar
            position="sticky"
            elevation={0}
            sx={{
              backgroundColor: "#ECF6FE",
            }}
          >
            <Toolbar variant="dense" sx={{ px: "1rem!important" }}>
              <LbTitle
                backAction={handleClose}
                icon={<Close color="primary" />}
                iconRight
                typoProps={{ color: "primary.main" }}
                tooltip="Back to variables list"
              >
                {data?.name ? "Edit" : "Add"} Variable
              </LbTitle>
            </Toolbar>
          </AppBar>

          <FormikProvider value={formik}>
            <Stack spacing={3.5} px={2} pt={2} pb={10} overflow={"scroll"}>
              <LbTextArea
                inputProps={{ minRows: 2 }}
                name={`label`}
                placeholder="Enter variable title"
                label="Variable title"
                required
              />

              <LbSmartSelect
                name={`type`}
                placeholder="Select input type"
                label="Input type"
                options={VARIABLE_TYPES.labelValue}
                required
                callback={(selected) => {
                  if (
                    includes(
                      [
                        VARIABLE_TYPES.key.STATIC,
                        VARIABLE_TYPES.key.TEXT,
                        VARIABLE_TYPES.key.NUMBER,
                      ],
                      selected?.value
                    )
                  ) {
                    formik?.setFieldValue(`options`, []);
                    formik?.setFieldValue(`multiple`, false);
                    formik?.setFieldValue(`selectAll`, false);
                  }

                  if (
                    selected?.value === VARIABLE_TYPES.key.NUMBER ||
                    (selected?.value === VARIABLE_TYPES.key.MCQ && formik?.values?.multiple)
                  ) {
                    formik?.setFieldValue(`max`, "");
                    formik?.setFieldValue(`min`, "");
                  } else {
                    formik?.setFieldValue(`max`, "");
                    formik?.setFieldValue(`min`, "");
                  }
                }}
              />

              {((formik?.values?.type?.value === VARIABLE_TYPES.key.MCQ &&
                formik?.values?.multiple) ||
                formik?.values?.type?.value === VARIABLE_TYPES.key.NUMBER) && (
                <Stack direction={"row"} spacing={2}>
                  <LbNumberField name={`min`} placeholder="MIN" label="Min" />
                  <LbNumberField name={`max`} placeholder="MAX" label="Max" />
                </Stack>
              )}

              {formik?.values?.type?.value === VARIABLE_TYPES.key.MCQ && [
                <LbTags
                  required
                  name={`options`}
                  placeholder="Enter options"
                  label="Input options"
                />,
                <LbSwitch name={`multiple`} label="Allow multiple selection" />,
              ]}

              {formik?.values?.type?.value === VARIABLE_TYPES.key.DATE && (
                <Stack direction={"row"} spacing={2}>
                  <LbSwitch name={`disableFuture`} label="Disable future date selection" />
                  <LbTextField name={`dateFormat`} label="Date format" />
                </Stack>
              )}

              {formik?.values?.multiple &&
                formik?.values?.type?.value === VARIABLE_TYPES.key.MCQ && (
                  <LbSwitch name={`selectAll`} label="Allow select all" />
                )}

              {formik?.values?.type?.value === VARIABLE_TYPES.key.STATIC && (
                <LbTextArea
                  inputProps={{ minRows: 2 }}
                  name={`value`}
                  placeholder="Enter variable value"
                  label="Variable value"
                  required={formik?.values?.type?.value === VARIABLE_TYPES.key.STATIC}
                />
              )}

              {!includes(
                [VARIABLE_TYPES.key.STATIC, VARIABLE_TYPES.key.DATE],
                formik?.values?.type?.value
              ) && (
                <LbTextArea
                  inputProps={{ minRows: 1 }}
                  name={`placeholder`}
                  placeholder="Enter input placeholder"
                  label="Input placeholder"
                />
              )}

              <LbTextArea
                inputProps={{ minRows: 2 }}
                name={`description`}
                placeholder="Enter description"
                label="Variable description"
              />
            </Stack>
          </FormikProvider>

          <LbActionFooter
            sx={{
              mt: "auto!important",
              position: "sticky",
              bottom: 0,
              justifyContent: "space-between",
            }}
          >
            <LbButton
              type="button"
              disabled={formik?.isSubmitting}
              color="warning"
              onClick={handleClose}
            >
              Cancel
            </LbButton>

            <Stack direction="row" justifyContent={"end"} spacing={2}>
              <LbButton
                color="error"
                type="button"
                disabled={formik?.isSubmitting || !formik?.dirty}
                onClick={formik?.resetForm}
                hidden={true}
              >
                Reset
              </LbButton>
              <LbButton
                type="submit"
                onClick={formik?.handleSubmit}
                loading={formik?.isSubmitting}
                disabled={!formik?.dirty}
              >
                {data?.name ? "Update" : "Create"}
              </LbButton>
            </Stack>
          </LbActionFooter>
        </Stack>
      </Drawer>
    </Fragment>
  );
}

export default engine(AddEditVariable);
