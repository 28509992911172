import {
  getRequest,
  orgEndpoints,
  putRequest,
  useReactQuery,
} from "@lb/frontend";
import { organizationsClientUrls } from "@lb/utils";
import React, { useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

const engine =
  (Component) =>
  ({ ...props }) => {
    const { organizationId } = useParams();
    const [moduleLoading, setModuleLoading] = useState({});
    const navigate = useNavigate();
    const { module } = useParams();

    const changeModule = (m) => {
      navigate(
        generatePath(organizationsClientUrls.modules.base, {
          organizationId,
          module: m,
        })
      );
    };

    const {
      data: { data = {} } = {},
      isLoading,
      refetch,
    } = useReactQuery({
      queryKeys: ["organization-modules", organizationId],
      apiCall: () =>
        getRequest({
          url: orgEndpoints.getById(organizationId).modules,
          baseUrl: "master",
        }),
      options: {
        enabled: Boolean(organizationId),
      },
    });

    const toggleModuleActivation = async (moduleName, value) => {
      setModuleLoading((prev) => ({ ...prev, [moduleName]: true }));
      await putRequest({
        url: orgEndpoints.moduleActivation(organizationId),
        data: { module: moduleName, active: value },
        baseUrl: "master",
      });
      await refetch();
      changeModule(value ? moduleName : module === moduleName ? "iam" : module);
      setModuleLoading((prev) => ({ ...prev, [moduleName]: false }));
    };

    // const formik = useFormik({
    //   initialValues: isEmpty(data)
    //     ? {}
    //     : {
    //         admin: data.admin,
    //         administrativeDepartment: data.administrativeDepartment,
    //         modules: data.modules,
    //       },
    //   enableReinitialize: true,
    //   validationSchema: Yup.object({
    //     admin: YupObjSchema("_id"),
    //     administrativeDepartment: YupObjSchema("_id"),
    //     modules: Yup.object({
    //       iam: Yup.object({
    //         users: Yup.object({
    //           linkExpiry: Yup.object({
    //             invitation: Yup.object({
    //               duration: Yup.string().required("Required"),
    //             }),
    //             signature: Yup.object({
    //               duration: Yup.string().required("Required"),
    //             }),
    //           }),
    //         }),
    //         vendors: Yup.object({
    //           linkExpiry: Yup.object({
    //             invitation: Yup.object({
    //               duration: Yup.string().required("Required"),
    //             }),
    //             signature: Yup.object({
    //               duration: Yup.string().required("Required"),
    //             }),
    //           }),
    //         }),
    //       }),
    //     }),
    //   }),
    //   onSubmit: (values, { setSubmitting, resetForm }) => {
    //     putRequest({
    //       url: orgEndpoints.update(data?._id),
    //       data: values,
    //       baseUrl: "master",
    //     })
    //       .then((res) => {
    //         resetForm(values);
    //         refetch();
    //       })
    //       .finally(() => setSubmitting(false));
    //   },
    // });

    const updateModuleConfig = (values, { setSubmitting, resetForm }) => {
      putRequest({
        url: orgEndpoints.update(data?._id),
        data: values,
        baseUrl: "master",
      })
        .then((res) => {
          resetForm(values);
          refetch();
        })
        .finally(() => setSubmitting(false));
    };

    return (
      <Component
        {...props}
        {...{
          data,
          module,
          refetch,
          isLoading,
          changeModule,
          moduleLoading,
          toggleModuleActivation,
          updateModuleConfig,
        }}
      />
    );
  };

export default engine;
