import { LbButton, LbTextField } from "@lb/frontend";
import { Box, Grid, Stack, Toolbar, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import React, { Fragment } from "react";
import Actions from "./components/Actions";
import AltActions from "./components/AltActions";
import Rules from "./components/Rules";
import WorkflowList from "./components/WorkflowList";
import engine, { generateWorkflowName } from "./engine";

const workFlowBody = () => ({
  title: generateWorkflowName(),
  conjunction: "and",
  rules: [{ source: "", operator: "", value: "" }],
  actions: [{ target: "", action: "", payload: "" }],
  altActions: [{ target: "", action: "", payload: "" }],
});

function Workflows({ values, $workflow, setFieldValue, theme }) {
  if (isEmpty(values?.workflows))
    return (
      <Stack height="50vh" justifyContent="center" alignItems="center">
        <LbButton
          size="large"
          type="button"
          onClick={() => setFieldValue("workflows", [workFlowBody(), ...values.workflows])}
        >
          Create first workflow
        </LbButton>
      </Stack>
    );

  return (
    <Fragment>
      <Box
        pt={2}
        sx={{
          backgroundImage:
            "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-0 -0 10 10'%3E%3Cpath d='M 0 1 L 10 1 M 1 0 L 1 10' stroke='%23CCCCCC' stroke-dasharray='1' stroke-width='0.1' /%3E%3C/svg%3E\")",
          backgroundSize: "40px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <WorkflowList {...{ $workflow, workFlowBody }} />
          </Grid>

          <Grid item xs={10}>
            <Stack spacing={2} pt={2}>
              <LbTextField
                name={`workflows.${$workflow.selected}.title`}
                label="Workflow name"
                placeholder="Enter workflow name"
              />

              <Typography variant="h5" fontWeight="bolder" fontFamily="courier">
                IF
              </Typography>
              <Rules name={`workflows.${$workflow.selected}`} />
              <Actions name={`workflows.${$workflow.selected}`} />
              <AltActions name={`workflows.${$workflow.selected}`} />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Toolbar />
    </Fragment>
  );
}

export default engine(Workflows);