import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import { getNodeFragment } from "@lb/utils";
import { Button, Paper, Typography } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

const Control = memo(({ arrayHelpers, nodeType, icon, disabled, text }) => {
  const ref = useRef(null);
  const [isDragging, setDragging] = useState(false);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;
    return draggable({
      element,
      getInitialData: () => ({ nodeType: nodeType }),
      onDragStart() {
        setDragging(true);
      },
      onDrop() {
        setDragging(false);
        setPreview(null);
      },
      onGenerateDragPreview({ nativeSetDragImage }) {
        setCustomNativeDragPreview({
          nativeSetDragImage,
          render({ container }) {
            setPreview(container);
          },
        });
      },
    });
  }, []);

  return (
    <Button
      ref={ref}
      variant="text"
      size="small"
      startIcon={icon}
      onClick={() => arrayHelpers.push(getNodeFragment(nodeType))}
      sx={{
        opacity: isDragging ? 0.5 : 1,
        cursor: isDragging ? "grabbing" : "grab",
        textWrap: "nowrap",
        color: "grey.600",
        "&:hover": {
          color: "primary.main",
        },
        px: 1,
      }}
      disabled={disabled}
    >
      {text}
      {preview &&
        createPortal(
          <Paper
            sx={{ py: 0.5, px: 2, border: "2px solid", borderColor: "primary.main", opacity: 1 }}
          >
            <Typography color="primary.main" m={0} variant="body2">
              {text}
            </Typography>
          </Paper>,
          preview
        )}
    </Button>
  );
});

export default Control;
