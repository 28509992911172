import {
  countryEndpoints,
  getFlag,
  getStatutoryDetailsCharter,
  LbActionFooter,
  LbAddress,
  LbAutocomplete,
  LbButton,
  LbDatePicker,
  LbPatternField,
  LbSearch,
  LbSelect,
  LbSlider,
  LbTabs,
  LbTextField,
  LbTitle,
} from "@lb/frontend";
import { Close, Female, Label, Male, Transgender } from "@mui/icons-material";
import {
  alpha,
  AppBar,
  Avatar,
  Box,
  Button,
  CardActionArea,
  Collapse,
  Divider,
  Drawer,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { FieldArray, FormikProvider } from "formik";
import moment from "moment";
import React, { Children, Fragment } from "react";
import engine from "./engine";
import {
  AADHAR_PATTERN,
  AADHAR_REGEX,
  BUSINESS_CATEGORIES,
  BUSINESS_TYPES,
  CIN_PATTERN,
  CIN_REGEX,
  CONTACT_NUMBER_PATTERN,
  GENDERS,
  getInitials,
  GOVERNMENT_IDS,
  GSTIN_PATTERN,
  GSTIN_REGEX,
  INDUSTRY_OPTIONS,
  LLPIN_PATTERN,
  LLPIN_REGEX,
  MSME_PATTERN,
  MSME_REGEX,
  ORG_TYPES,
  PAN_PATTERN,
  PAN_REGEX,
  TAN_PATTERN,
  TAN_REGEX,
  TDS_SECTION_OPTIONS,
  UDYAM_PATTERN,
  UDYAM_REGEX,
  VENDOR_TYPE_OPTIONS,
} from "@lb/utils";
import { includes, map, size } from "lodash";
import { FiTrash2 } from "react-icons/fi";

export const STATUTORY_PATTERN_REGEX = {
  pan: {
    pattern: PAN_PATTERN,
    regex: PAN_REGEX,
  },
  cin: {
    pattern: CIN_PATTERN,
    regex: CIN_REGEX,
  },
  llpin: {
    pattern: LLPIN_PATTERN,
    regex: LLPIN_REGEX,
  },
  gstin: {
    pattern: GSTIN_PATTERN,
    regex: GSTIN_REGEX,
  },
  tan: {
    pattern: TAN_PATTERN,
    regex: TAN_REGEX,
  },
  aadhar: {
    pattern: AADHAR_PATTERN,
    regex: AADHAR_REGEX,
  },
  udyam: {
    pattern: UDYAM_PATTERN,
    regex: UDYAM_REGEX,
  },
  msme: {
    pattern: MSME_PATTERN,
    regex: MSME_REGEX,
  },
};

function TestData({ open, handleClose, formik, setCollapsed, isCollapsed, orgData, tab, setTab }) {
  const theme = useTheme();

  const metadataInputFields = orgData?.iam?.vendors?.inputFields?.metadata;

  const CollapseTitle = ({ collapseName, title, remove }) => {
    return (
      <Stack
        direction="row"
        component={CardActionArea}
        sx={{
          cursor: "pointer",
          borderRadius: "5px",
          padding: "0.1em 0.5em",
          boxSizing: "border-box",
          backgroundColor: "#ECF6FE",
        }}
        onClick={() => setCollapsed(collapseName)}
        alignItems="center"
        justifyContent="space-between"
        width={"100%"}
      >
        <Typography
          variant="body2"
          fontWeight={600}
          color="text.secondary"
          display={"inline-flex"}
          align="left"
          alignItems={"center"}
        >
          <Label
            fontSize="inherit"
            color="primary"
            sx={{
              transitionDuration: ".3s",
              transform: isCollapsed(collapseName) ? "rotate(90deg)!important" : "none",
            }}
          />
          <span>&nbsp;{title}&nbsp;</span>
        </Typography>
        {remove && (
          <IconButton
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              remove?.();
            }}
          >
            <Icon color="error" fontSize="inherit">
              <FiTrash2 />
            </Icon>
          </IconButton>
        )}
      </Stack>
    );
  };

  return (
    <Fragment>
      <Drawer
        open={open}
        anchor="right"
        PaperProps={{ sx: { width: "75%", display: "flex", direction: "column" } }}
        onClose={handleClose}
      >
        <AppBar
          position="sticky"
          elevation={0}
          sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.1) }}
        >
          <Toolbar variant="dense" sx={{ px: "1rem!important" }}>
            <LbTitle
              backAction={handleClose}
              icon={<Close color="primary" />}
              iconRight
              typoProps={{ color: "primary.main" }}
              tooltip="Back to contract transcript"
            >
              Test Data
            </LbTitle>
          </Toolbar>
        </AppBar>

        <Box overflow={"auto"} pb={5} px={2}>
          <LbTabs
            tabs={[
              { label: "Organization", value: "organization" },
              { label: "Contract", value: "contract" },
              { label: "Parties", value: "parties" },
            ]}
            activeTab={tab.selected}
            handleTabChange={tab.changeTab}
          />
          <Divider />
          <FormikProvider value={formik}>
            <Grid container px={0.5} pt={3} rowSpacing={3} columnSpacing={2}>
              {{
                organization: (
                  <Fragment>
                    <Grid item md={8}>
                      <LbTextField
                        required
                        name="organization.name"
                        label="Organization Name"
                        placeholder="Enter organization name"
                      />
                    </Grid>
                    <Grid item md={4}>
                      <LbPatternField
                        name="organization.abbreviation"
                        label="Abbreviation"
                        placeholder="Enter abbreviation"
                        pattern={"$$$"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Button
                                disabled={!formik.values?.organization?.name}
                                onClick={() =>
                                  formik.setFieldValue(
                                    "organization.abbreviation",
                                    getInitials(formik.values?.organization?.name)
                                  )
                                }
                                size="small"
                                variant="text"
                                color="info"
                              >
                                Auto
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <LbTextField
                        required
                        name="organization.email"
                        label="Organization Email"
                        placeholder="Enter Organization Email"
                      />
                    </Grid>
                    <Grid item md={4}>
                      <LbAutocomplete
                        required
                        name="organization.industry"
                        options={INDUSTRY_OPTIONS.options}
                        placeholder="Select industry"
                        label="Industry"
                      />
                    </Grid>
                    <Grid item md={4}>
                      <LbTextField
                        name="organization.website"
                        label="Website"
                        placeholder="Enter website url"
                      />
                    </Grid>
                    <Grid item md={3}>
                      <LbPatternField
                        name="organization.contactNo"
                        label="Contact Number"
                        placeholder="Enter contact number"
                        pattern={CONTACT_NUMBER_PATTERN}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {formik.values.organization.location?.country?.phoneCode}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <LbSelect
                        required
                        name="organization.orgType"
                        label="Organization Type"
                        options={ORG_TYPES.labelValue}
                        placeholder="Select Organization Type"
                      />
                    </Grid>
                    <Grid item md={3}>
                      <LbSelect
                        required
                        name="organization.businessType"
                        label="Business Type"
                        options={BUSINESS_TYPES.labelValue}
                        placeholder="Select Business Type"
                      />
                    </Grid>
                    <Grid item md={3}>
                      <LbSelect
                        required
                        name="organization.businessCategory"
                        label="Business Category"
                        options={BUSINESS_CATEGORIES.labelValue}
                        placeholder="Select business category"
                      />
                    </Grid>

                    <Grid item md={3}>
                      <LbSearch
                        required
                        name="organization.location.country"
                        label="Country"
                        optionLabel={"name"}
                        queryParamName="search"
                        placeholder="Search country"
                        requestUrl={countryEndpoints.base}
                        baseUrl="auth"
                        callback={(newVal) =>
                          newVal?.code !== formik.values.organization.location?.country?.code &&
                          formik.setFieldValue("organization.location.address", null)
                        }
                        additionalProperties={["code", "flag", "phoneCode"]}
                        InputProps={
                          formik.values.organization.location?.country?.flag && {
                            startAdornment: (
                              <InputAdornment position="start">
                                <Avatar
                                  src={getFlag(formik.values.organization.location?.country?.code)}
                                  variant="square"
                                  sx={{
                                    height: "20px",
                                    width: "30px",
                                    border: 1,
                                    ml: 0.5,
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }
                        }
                        renderOption={(optionProps, option) => {
                          return (
                            <ListItem {...optionProps} dense>
                              <ListItemAvatar sx={{ minWidth: 0, pr: 2 }}>
                                <Avatar
                                  src={getFlag(option?.code)}
                                  variant="square"
                                  sx={{ height: 32, width: 32, border: 1, borderColor: "grey.300" }}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={option?.name}
                                secondary={option?.code}
                              ></ListItemText>
                            </ListItem>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={9}>
                      <LbAddress
                        required
                        name="organization.location.address"
                        label="Organization Address"
                        placeholder="Enter organization address"
                        queryParams={{
                          country: formik.values.organization.location?.country?.code,
                        }}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <LbPatternField
                        name="organization.turnover"
                        label="Annual turnover"
                        placeholder="Enter annual turnover"
                        pattern="0000000000000000000"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FieldArray
                        name="organization.signatories"
                        render={(arrayHelpers) => (
                          <Stack spacing={3}>
                            <Stack
                              direction={"row"}
                              alignItems="center"
                              justifyContent={"space-between"}
                            >
                              <Typography variant="body2" color="text.secondary" fontWeight="bold">
                                Signatories
                              </Typography>
                              <LbButton
                                onClick={() =>
                                  arrayHelpers.push({
                                    name: "Untitled",
                                    email: "example@signatory.com",
                                    designation: "Untitled",
                                  })
                                }
                              >
                                Add
                              </LbButton>
                            </Stack>
                            {Children.toArray(
                              map(
                                formik.values?.organization?.signatories,
                                (signatory, signatoryIndex) => (
                                  <Stack direction="row" spacing={2}>
                                    <Typography
                                      m={0}
                                      p={0}
                                      color={"divider"}
                                      variant="h5"
                                      fontWeight="bold"
                                    >
                                      #{signatoryIndex + 1}
                                    </Typography>
                                    <LbTextField
                                      name={`organization.signatories[${signatoryIndex}].name`}
                                      label="Signatory Name"
                                      placeholder="Enter signatory name"
                                    />
                                    <LbTextField
                                      name={`organization.signatories[${signatoryIndex}].email`}
                                      label="Signatory Email"
                                      placeholder="Enter signatory email"
                                    />
                                    <LbTextField
                                      name={`organization.signatories[${signatoryIndex}].designation`}
                                      label="Signatory Designation"
                                      placeholder="Enter signatory designation"
                                    />

                                    <IconButton
                                      color="error"
                                      type="button"
                                      onClick={() => arrayHelpers.remove(signatoryIndex)}
                                      disabled={size(formik.values?.organization?.signatories) < 2}
                                    >
                                      <Icon color="inherit" fontSize="small">
                                        <FiTrash2 />
                                      </Icon>
                                    </IconButton>
                                  </Stack>
                                )
                              )
                            )}
                          </Stack>
                        )}
                      />
                    </Grid>
                  </Fragment>
                ),
                contract: (
                  <Fragment>
                    <Grid item xs={8}>
                      <LbTextField
                        required
                        name="contract.name"
                        placeholder="Enter contract name"
                        label="Contract Name"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <LbTextField
                        name="contract.code"
                        label="Contract Code"
                        placeholder="Enter contract code"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <LbDatePicker
                        name="contract.timeline.execution"
                        label="Signing Date"
                        required
                        dateProps={{
                          disableFuture: false,
                        }}
                        inputFormat={orgData.dateFormat}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <LbDatePicker
                        name="contract.timeline.effective"
                        label="Effective Date"
                        required
                        dateProps={{
                          disableFuture: false,
                        }}
                        inputFormat={orgData.dateFormat}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <LbDatePicker
                        name="contract.timeline.expiry"
                        label="Expiry Date"
                        required
                        disablePast={true}
                        disableFuture={false}
                        maxDate={moment(formik.values.contract?.timeline?.effective)
                          .add({ years: 20, months: 12, days: 30 })
                          .toDate()}
                        onChange={(newDate) => {
                          const duration = moment.duration(
                            newDate.diff(formik.values.contract?.timeline?.effective)
                          );
                          formik.setFieldValue("contract.duration", {
                            years: duration.years(),
                            months: duration.months(),
                            days: duration.days(),
                          });
                        }}
                        inputFormat={orgData.dateFormat}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <LbSlider
                        required
                        name="contract.duration.years"
                        label="Years"
                        sliderProps={{ min: 0, max: 20 }}
                        displayValue={(val) => {
                          return val + " Y";
                        }}
                        onChange={(newVal) => {
                          formik.setFieldValue(
                            "contract.timeline.expiry",
                            moment(formik.values.contract?.timeline?.effective)
                              .add({ years: newVal })
                              .toDate()
                          );
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <LbSlider
                        required
                        name="contract.duration.months"
                        label="Months"
                        sliderProps={{
                          min: 0,
                          max: 12,
                        }}
                        onChange={(newVal) => {
                          formik.setFieldValue(
                            "contract.timeline.expiry",
                            moment(formik.values.contract?.timeline?.effective)
                              .add({ months: newVal })
                              .toDate()
                          );
                        }}
                        displayValue={(val) => {
                          return val + " M";
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <LbSlider
                        required
                        name="contract.duration.days"
                        label="Days"
                        sliderProps={{ min: 0, max: 30 }}
                        displayValue={(val) => {
                          return val + " D";
                        }}
                        onChange={(newVal) => {
                          formik.setFieldValue(
                            "contract.timeline.expiry",
                            moment(formik.values.contract?.timeline?.effective)
                              .add({ days: newVal })
                              .toDate()
                          );
                        }}
                      />
                    </Grid>
                  </Fragment>
                ),
                parties: (
                  <Grid item xs={12}>
                    <FieldArray
                      name="contract.vendors"
                      render={(arrayHelpers) => (
                        <Stack spacing={3} width={"100%"}>
                          <LbButton
                            type="button"
                            onClick={() =>
                              arrayHelpers.push({
                                name: "",
                                age: "",
                                signatories: [
                                  {
                                    name: "Untitled",
                                    email: "example@signatory.com",
                                    designation: "Untitled",
                                  },
                                ],
                              })
                            }
                          >
                            Add Party
                          </LbButton>
                          {Children.toArray(
                            map(formik.values.contract.vendors, (vendor, vendorIndex) => {
                              let isIndividual =
                                vendor?.type === VENDOR_TYPE_OPTIONS.key.INDIVIDUAL;
                              let isIndia = vendor?.location?.country?.code === "IN";
                              let statutoryDetailsCharter = getStatutoryDetailsCharter(
                                vendor,
                                orgData?.modules
                              );

                              return (
                                <div>
                                  <CollapseTitle
                                    collapseName={`metadata-${vendorIndex}`}
                                    title={vendor?.name || "Untitled"}
                                    remove={
                                      size(formik.values.contract.vendors) < 2
                                        ? null
                                        : () => arrayHelpers.remove(vendorIndex)
                                    }
                                  />

                                  <Collapse
                                    unmountOnExit
                                    in={isCollapsed(`metadata-${vendorIndex}`)}
                                  >
                                    <Grid container columnSpacing={2} rowSpacing={3} pt={3}>
                                      <Grid item md={3} sm={6} xs={12}>
                                        <LbSelect
                                          required
                                          name={`contract.vendors.${vendorIndex}.type`}
                                          label="Type"
                                          placeholder="Select type"
                                          options={VENDOR_TYPE_OPTIONS.labelValue}
                                        />
                                      </Grid>
                                      <Grid item md={6} xs={12}>
                                        <LbTextField
                                          required
                                          name={`contract.vendors.${vendorIndex}.name`}
                                          label={`${isIndividual ? "" : "Business"} Name`}
                                          placeholder={`Enter ${
                                            isIndividual ? "individual" : "business"
                                          } name`}
                                        />
                                      </Grid>
                                      {isIndividual && (
                                        <Fragment>
                                          {metadataInputFields?.fatherName && (
                                            <Grid item md={3} sm={6} xs={12}>
                                              <LbTextField
                                                name={`contract.vendors.${vendorIndex}.fatherName`}
                                                label="Father's Name"
                                                placeholder="Enter Father Name"
                                              />
                                            </Grid>
                                          )}
                                          <Grid item md={3} sm={6} xs={12}>
                                            <LbSelect
                                              required
                                              label="Gender"
                                              name={`contract.vendors.${vendorIndex}.gender`}
                                              placeholder="Select gender"
                                              options={GENDERS.labelValue}
                                              inputProps={{
                                                InputProps: {
                                                  startAdornment: (
                                                    <InputAdornment position="start">
                                                      {
                                                        {
                                                          male: <Male />,
                                                          female: <Female />,
                                                          other: <Transgender />,
                                                        }[vendor?.gender]
                                                      }
                                                    </InputAdornment>
                                                  ),
                                                },
                                              }}
                                            />
                                          </Grid>
                                        </Fragment>
                                      )}
                                      <Grid item md={3} sm={6} xs={12}>
                                        <LbPatternField
                                          name={`contract.vendors.${vendorIndex}.abbreviation`}
                                          pattern={"$$$$$$$$$$$$$$"}
                                          label="Abbreviation"
                                          placeholder="Enter abbreviation"
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <Button
                                                  disabled={!vendor?.name}
                                                  onClick={() =>
                                                    formik.setFieldValue(
                                                      `contract.vendors.${vendorIndex}.abbreviation`,
                                                      getInitials(vendor?.name)
                                                    )
                                                  }
                                                  size="small"
                                                  variant="text"
                                                  color="info"
                                                >
                                                  Auto
                                                </Button>
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </Grid>
                                      <Grid item md={3} sm={6} xs={12}>
                                        <LbTextField
                                          required
                                          name={`contract.vendors.${vendorIndex}.email`}
                                          label="Business Email"
                                          placeholder="Enter business email"
                                        />
                                      </Grid>

                                      <Grid item md={3} sm={6} xs={12}>
                                        <LbSearch
                                          required
                                          name={`contract.vendors.${vendorIndex}.location.country`}
                                          label="Country"
                                          optionLabel={"name"}
                                          queryParamName="search"
                                          placeholder="Search country"
                                          requestUrl={countryEndpoints.base}
                                          baseUrl="auth"
                                          callback={(newVal) =>
                                            newVal?.code !== vendor?.location?.country?.code &&
                                            formik.setFieldValue(
                                              `contract.vendors.${vendorIndex}.location.address`,
                                              null
                                            )
                                          }
                                          additionalProperties={["code", "flag", "phoneCode"]}
                                          InputProps={
                                            vendor?.location?.country?.flag && {
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  <Avatar
                                                    src={getFlag(vendor?.location?.country?.code)}
                                                    variant="square"
                                                    sx={{
                                                      height: 30,
                                                      width: 30,
                                                      border: 1,
                                                      ml: 0.5,
                                                    }}
                                                  />
                                                </InputAdornment>
                                              ),
                                            }
                                          }
                                          renderOption={(optionProps, option) => {
                                            return (
                                              <ListItem {...optionProps} dense>
                                                <ListItemAvatar sx={{ minWidth: 0, pr: 2 }}>
                                                  <Avatar
                                                    src={getFlag(option?.code, "4x3")}
                                                    variant="rounded"
                                                    sx={{
                                                      height: 32,
                                                      width: 32,
                                                      border: 1,
                                                      borderColor: "grey.300",
                                                    }}
                                                  />
                                                </ListItemAvatar>
                                                <ListItemText
                                                  primary={option?.name}
                                                  secondary={option?.code}
                                                ></ListItemText>
                                              </ListItem>
                                            );
                                          }}
                                        />
                                      </Grid>

                                      <Grid item md={3} sm={6} xs={12}>
                                        <LbSelect
                                          required
                                          name={`contract.vendors.${vendorIndex}.orgType`}
                                          label="Organization Type"
                                          options={
                                            isIndividual
                                              ? ORG_TYPES.forIndividual().labelValue
                                              : ORG_TYPES.forNonIndividual().labelValue
                                          }
                                          placeholder="Select organization type"
                                        />
                                      </Grid>

                                      {isIndia && metadataInputFields?.businessCategory && (
                                        <Grid item md={3} sm={6} xs={12}>
                                          <LbSelect
                                            required
                                            label="Category"
                                            name={`contract.vendors.${vendorIndex}.businessCategory`}
                                            placeholder="Select vendor category"
                                            options={BUSINESS_CATEGORIES.labelValue}
                                          />
                                        </Grid>
                                      )}

                                      {metadataInputFields?.businessType && (
                                        <Grid item md={3} sm={6} xs={12}>
                                          <LbSelect
                                            required={metadataInputFields?.businessType?.required}
                                            name={`contract.vendors.${vendorIndex}.businessType`}
                                            label="Business Type"
                                            placeholder="Select business type"
                                            options={BUSINESS_TYPES.labelValue}
                                          />
                                        </Grid>
                                      )}
                                      {metadataInputFields?.industry && (
                                        <Grid item md={3} sm={6} xs={12}>
                                          <LbAutocomplete
                                            required={metadataInputFields?.industry?.required}
                                            name={`contract.vendors.${vendorIndex}.industry`}
                                            label="Industry"
                                            placeholder="Select industry"
                                            options={INDUSTRY_OPTIONS.options}
                                          />
                                        </Grid>
                                      )}

                                      {metadataInputFields?.contactNo && (
                                        <Grid item md={3} sm={6} xs={12}>
                                          <LbPatternField
                                            required={metadataInputFields?.contactNo?.required}
                                            name={`contract.vendors.${vendorIndex}.contactNo`}
                                            label="Contact Number"
                                            pattern={CONTACT_NUMBER_PATTERN}
                                            placeholder="Preferably use whatsapp number"
                                            InputProps={{
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  {vendor.location?.country?.phoneCode}
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        </Grid>
                                      )}
                                      {metadataInputFields?.turnover && (
                                        <Grid item md={3} sm={6} xs={12}>
                                          <LbPatternField
                                            required={metadataInputFields?.turnover?.required}
                                            name={`contract.vendors.${vendorIndex}.turnover`}
                                            label="Annual turnover"
                                            placeholder="Enter annual turnover"
                                            pattern="0000000000000000000"
                                          />
                                        </Grid>
                                      )}
                                      {metadataInputFields?.reference && (
                                        <Grid item md={3} sm={6} xs={12}>
                                          <LbTextField
                                            required={metadataInputFields?.reference?.required}
                                            name={`contract.vendors.${vendorIndex}.reference`}
                                            label="Reference Name"
                                            placeholder="Enter reference name"
                                          />
                                        </Grid>
                                      )}
                                      {metadataInputFields?.website && (
                                        <Grid item md={3} sm={6} xs={12}>
                                          <LbTextField
                                            required={metadataInputFields?.website?.required}
                                            name={`contract.vendors.${vendorIndex}.website`}
                                            label="Website"
                                            placeholder="Enter Website Url"
                                          />
                                        </Grid>
                                      )}
                                      <Grid item xs={12} key={vendor.location?.country?.code}>
                                        <LbAddress
                                          required
                                          name={`contract.vendors.${vendorIndex}.location.address`}
                                          label={
                                            isIndividual
                                              ? "Communication Address"
                                              : "Registered Office Address"
                                          }
                                          placeholder={
                                            isIndividual
                                              ? "Enter communication Address"
                                              : "Enter registerd office address"
                                          }
                                          queryParams={{
                                            country: vendor.location?.country?.code,
                                          }}
                                        />
                                      </Grid>

                                      {Children.toArray(
                                        map(GOVERNMENT_IDS.forVendor().keys, (key) => {
                                          if (
                                            includes(
                                              [GOVERNMENT_IDS.key.UDYAM, GOVERNMENT_IDS.key.MSME],
                                              key
                                            )
                                          )
                                            return null;

                                          if (!statutoryDetailsCharter[key].render) return null;

                                          if (STATUTORY_PATTERN_REGEX[key]?.regex)
                                            return (
                                              <Grid item md={6} xs={12}>
                                                <LbPatternField
                                                  required={statutoryDetailsCharter[key].required}
                                                  label={GOVERNMENT_IDS._[key]}
                                                  pattern={STATUTORY_PATTERN_REGEX[key].pattern}
                                                  name={`contract.vendors.${vendorIndex}.governmentId.${key}`}
                                                  placeholder={`Enter ${GOVERNMENT_IDS._[key]}`}
                                                />
                                              </Grid>
                                            );

                                          return (
                                            <Grid item md={6} xs={12}>
                                              <LbTextField
                                                required={statutoryDetailsCharter[key].required}
                                                label={GOVERNMENT_IDS._[key]}
                                                name={`contract.vendors.${vendorIndex}.governmentId.${key}`}
                                                placeholder={`Enter ${GOVERNMENT_IDS._[key]}`}
                                              />
                                            </Grid>
                                          );
                                        })
                                      )}

                                      {statutoryDetailsCharter?.tdsSection?.render && (
                                        <Grid item md={6} xs={12}>
                                          <LbSelect
                                            required={statutoryDetailsCharter?.tdsSection?.required}
                                            name={`contract.vendors.${vendorIndex}.tdsSection`}
                                            label="TDS Section"
                                            placeholder="Select TDS section"
                                            options={TDS_SECTION_OPTIONS.labelValue}
                                          />
                                        </Grid>
                                      )}

                                      {/* {statutoryDetailsCharter?.udyamMsme?.render && (
                                        <Grid item md={6} xs={12}>
                                          <LbPatternField
                                            required={statutoryDetailsCharter?.udyamMsme?.required}
                                            name={`governmentId.${
                                              udyamMsmeType === GOVERNMENT_IDS.key.MSME
                                                ? GOVERNMENT_IDS.key.MSME
                                                : GOVERNMENT_IDS.key.UDYAM
                                            }`}
                                            label="MSME/ UDYAM  Number"
                                            pattern={
                                              udyamMsmeType === GOVERNMENT_IDS.key.MSME
                                                ? MSME_PATTERN
                                                : UDYAM_PATTERN
                                            }
                                            placeholder={`Enter ${
                                              udyamMsmeType === GOVERNMENT_IDS.key.MSME
                                                ? GOVERNMENT_IDS._[GOVERNMENT_IDS.key.MSME]
                                                : GOVERNMENT_IDS._[GOVERNMENT_IDS.key.UDYAM]
                                            } Number`}
                                            sx={{ "& .MuiOutlinedInput-root": { pl: 1 } }}
                                            InputProps={{
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  <Select
                                                    labelId="demo-select-small"
                                                    id="demo-select-small"
                                                    size="small"
                                                    value={udyamMsmeType}
                                                    onChange={(e) => {
                                                      setUdyamMsmeType(e.target.value);
                                                    }}
                                                    sx={{
                                                      "& *": {
                                                        border: "none",
                                                        fontSize: "14px!important",
                                                      },
                                                      "& .MuiSelect-select": {
                                                        pr: "25px!important",
                                                        py: "5px!important",
                                                        pl: 0,
                                                      },
                                                      "& svg": {
                                                        position: "absolute",
                                                      },
                                                      backgroundColor: alpha(
                                                        theme.palette.primary.light,
                                                        0.07
                                                      ),
                                                      borderRadius: "3px!important",
                                                    }}
                                                  >
                                                    <MenuItem value={GOVERNMENT_IDS.key.MSME}>
                                                      MSME
                                                    </MenuItem>
                                                    <MenuItem value={GOVERNMENT_IDS.key.UDYAM}>
                                                      UDYAM
                                                    </MenuItem>
                                                  </Select>
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        </Grid>
                                      )} */}

                                      <Grid item xs={12}>
                                        <FieldArray
                                          name={`contract.vendors.${vendorIndex}.signatories`}
                                          render={(arrayHelpers) => (
                                            <Stack spacing={3}>
                                              <Stack
                                                direction={"row"}
                                                alignItems="center"
                                                justifyContent={"space-between"}
                                              >
                                                <Typography
                                                  variant="body2"
                                                  color="text.secondary"
                                                  fontWeight="bold"
                                                >
                                                  Signatories
                                                </Typography>
                                                <LbButton
                                                  onClick={() =>
                                                    arrayHelpers.push({
                                                      name: "Untitled",
                                                      email: "example@signatory.com",
                                                      designation: "Untitled",
                                                    })
                                                  }
                                                >
                                                  Add
                                                </LbButton>
                                              </Stack>
                                              {Children.toArray(
                                                map(
                                                  formik.values?.contract.vendors[vendorIndex]
                                                    ?.signatories,
                                                  (signatory, signatoryIndex) => (
                                                    <Stack direction="row" spacing={2}>
                                                      <Typography
                                                        m={0}
                                                        p={0}
                                                        color={"divider"}
                                                        variant="h5"
                                                        fontWeight="bold"
                                                      >
                                                        #{signatoryIndex + 1}
                                                      </Typography>
                                                      <LbTextField
                                                        name={`contract.vendors.${vendorIndex}.signatories[${signatoryIndex}].name`}
                                                        label="Signatory Name"
                                                        placeholder="Enter signatory name"
                                                      />
                                                      <LbTextField
                                                        name={`contract.vendors.${vendorIndex}.signatories[${signatoryIndex}].email`}
                                                        label="Signatory Email"
                                                        placeholder="Enter signatory email"
                                                      />
                                                      <LbTextField
                                                        name={`contract.vendors.${vendorIndex}.signatories[${signatoryIndex}].designation`}
                                                        label="Signatory Designation"
                                                        placeholder="Enter signatory designation"
                                                      />

                                                      <IconButton
                                                        color="error"
                                                        type="button"
                                                        onClick={() =>
                                                          arrayHelpers.remove(signatoryIndex)
                                                        }
                                                        disabled={
                                                          size(
                                                            formik.values?.contract.vendors[
                                                              vendorIndex
                                                            ]?.signatories
                                                          ) < 2
                                                        }
                                                      >
                                                        <Icon color="inherit" fontSize="small">
                                                          <FiTrash2 />
                                                        </Icon>
                                                      </IconButton>
                                                    </Stack>
                                                  )
                                                )
                                              )}
                                            </Stack>
                                          )}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Collapse>
                                </div>
                              );
                            })
                          )}
                        </Stack>
                      )}
                    />
                  </Grid>
                ),
              }[tab.selected] ?? null}
            </Grid>
          </FormikProvider>
        </Box>
        <LbActionFooter sx={{ position: "sticky", bottom: 0, mt: "auto" }}>
          <LbButton color="warning" onClick={handleClose}>
            Close
          </LbButton>
          <Stack direction="row" spacing={2}>
            <LbButton color="error" onClick={formik.resetForm} disabled={!formik.dirty}>
              Reset
            </LbButton>
            <LbButton onClick={formik.handleSubmit} disabled={!formik.dirty}>
              Save
            </LbButton>
          </Stack>
        </LbActionFooter>
      </Drawer>
    </Fragment>
  );
}

export default engine(TestData);
