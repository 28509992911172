import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import { extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { getReorderDestinationIndex } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine"; // NEW
import {
  dropTargetForElements,
  monitorForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { EDITOR_NODE_TYPES, getNodeFragment, getNodeType } from "@lb/utils";
import {
  AssignmentReturnOutlined,
  FileCopy,
  LibraryBooksOutlined,
  Print,
  Save,
  Share,
  ViewWeekOutlined,
} from "@mui/icons-material";
import DataObjectIcon from "@mui/icons-material/DataObject";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import {
  AppBar,
  Backdrop,
  Box,
  Divider,
  Fab,
  Icon,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Stack,
  SvgIcon,
  Toolbar,
  Typography,
} from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import { cloneDeep, compact, get, includes, isArray, isEmpty, map, set } from "lodash";
import * as React from "react";
import { Children, Fragment, useEffect, useRef, useState } from "react";
import { BsTextParagraph } from "react-icons/bs";
import { GoNumber, GoTable, GoTypography, GoUnfold } from "react-icons/go";
import { LuLayoutTemplate } from "react-icons/lu";
import { RiFlowChart } from "react-icons/ri";
import { TiFlowMerge } from "react-icons/ti";
import invariant from "tiny-invariant";
import Control from "./components/Control";
import Structure from "./components/Structure";
import EmptyStructurePlaceholder from "./components/Structure.Empty";
import engine from "./engine";
import { AiOutlineNodeCollapse } from "react-icons/ai";
import { TbFileImport } from "react-icons/tb";

const cleanedArray = (arr) => {
  return compact(
    map(arr, (s) => {
      if (isArray(s?.text)) s.text = cleanedArray(s?.text);
      if (isArray(s?.ul)) s.ul = cleanedArray(s?.ul);
      if (isArray(s?.ol)) s.ol = cleanedArray(s?.ol);
      if (isArray(s?.stack)) s.stack = cleanedArray(s?.stack);
      if (isArray(s?.column)) s.column = cleanedArray(s?.column);
      if (isArray(s?.then)) s.then = cleanedArray(s?.then);
      if (isArray(s?.else)) s.else = cleanedArray(s?.else);
      if (isArray(s?.loop)) s.loop = cleanedArray(s?.loop);
      return s;
    })
  );
};

function Manuscript() {
  return (
    <Fragment>
      <FieldArray name="manuscript">
        {(arrayHelpers) => (
          <Fragment>
            <AppBar
              position="sticky"
              color="default"
              variant="outlined"
              elevation={4}
              sx={{ top: 7, px: 1, overflowX: "auto" }}
            >
              <Stack
                direction="row"
                alignItems="center"
                flexWrap={"nowrap"}
                divider={
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ borderColor: "info.main", borderWidth: "1px", borderStyle: "dashed" }}
                  />
                }
                sx={{ width: "100%" }}
                spacing={0}
                justifyContent={"space-between"}
              >
                {Children.toArray(
                  [
                    {
                      icon: <GoTypography />,
                      text: EDITOR_NODE_TYPES._.text,
                      nodeType: EDITOR_NODE_TYPES.key.TEXT,
                    },
                    {
                      icon: <BsTextParagraph />,
                      text: EDITOR_NODE_TYPES._.paragraph,
                      nodeType: EDITOR_NODE_TYPES.key.PARAGRAPH,
                    },
                    {
                      icon: <GoNumber />,
                      text: EDITOR_NODE_TYPES._.ol,
                      nodeType: EDITOR_NODE_TYPES.key.OL,
                    },
                    {
                      icon: <ScatterPlotIcon />,
                      text: EDITOR_NODE_TYPES._.ul,
                      nodeType: EDITOR_NODE_TYPES.key.UL,
                    },
                    {
                      icon: <ViewWeekOutlined />,
                      text: EDITOR_NODE_TYPES._.columns,
                      nodeType: EDITOR_NODE_TYPES.key.COLUMNS,
                    },
                    {
                      icon: <GoTable />,
                      text: EDITOR_NODE_TYPES._.table,
                      nodeType: EDITOR_NODE_TYPES.key.TABLE,
                      disabled: true,
                    },
                    {
                      icon: <GoUnfold />,
                      text: EDITOR_NODE_TYPES._.space,
                      nodeType: EDITOR_NODE_TYPES.key.SPACE,
                    },
                    {
                      icon: <LuLayoutTemplate />,
                      text: EDITOR_NODE_TYPES._.stack,
                      nodeType: EDITOR_NODE_TYPES.key.STACK,
                    },
                    {
                      icon: <DataObjectIcon />,
                      text: EDITOR_NODE_TYPES._.variable,
                      nodeType: EDITOR_NODE_TYPES.key.VARIABLE,
                    },
                    {
                      icon: <TiFlowMerge />,
                      text: EDITOR_NODE_TYPES._.decision,
                      nodeType: EDITOR_NODE_TYPES.key.DECISION,
                    },
                    {
                      icon: <RiFlowChart />,
                      text: EDITOR_NODE_TYPES._.loop,
                      nodeType: EDITOR_NODE_TYPES.key.LOOP,
                    },
                  ].map((item) => <Control {...item} {...{ arrayHelpers }} />)
                )}
              </Stack>
            </AppBar>
            <Divider />

            <Editor {...{ arrayHelpers }} />
          </Fragment>
        )}
      </FieldArray>

      
      <Toolbar />
    </Fragment>
  );
}

const Editor = React.memo(({ arrayHelpers }) => {
  const ref = useRef(null);
  const { values, setFieldValue } = useFormikContext();
  const [aboutToDrop, setAboutToDrop] = useState(false);

  useEffect(() => {
    return monitorForElements({
      onDrop({ location, source }) {
        const target = location.current.dropTargets[0];

        if (!target || target.data.index < 0 || includes(target.data.name, source.data.name)) {
          return;
        }

        const closestEdgeOfTarget = extractClosestEdge(target.data);
        if (!closestEdgeOfTarget) return;

        const finishIndex = getReorderDestinationIndex({
          startIndex: source.data.index,
          closestEdgeOfTarget,
          indexOfTarget: target.data.index,
          axis: "vertical",
        });

        if (
          finishIndex === source.data.index &&
          target.data.arrayHelpers.name === source.data.arrayHelpers.name
        ) {
          return;
        }

        if (
          typeof source.data.index === "number" &&
          !isEmpty(target.data.arrayHelpers) &&
          target.data.arrayHelpers.name
        ) {
          invariant(source.data.arrayHelpers);
          //! Moving inside same parent
          if (target.data.arrayHelpers.name === source.data.arrayHelpers.name) {
            target.data.arrayHelpers.move(source.data.index, finishIndex);
          }
          //! Moving accross nodes
          else {
            let manuscriptData = { manuscript: cloneDeep(values.manuscript) };
            const sourceData = get(manuscriptData, source.data.name);
            set(manuscriptData, source.data.name, undefined);

            const INSERT_INDEX =
              closestEdgeOfTarget === "bottom" ? target.data.index + 1 : target.data.index;
            get(manuscriptData, target.data.arrayHelpers.name).splice(INSERT_INDEX, 0, sourceData);
            manuscriptData.manuscript = compact(
              map(manuscriptData.manuscript, (script) => {
                if (isArray(script?.text)) script.text = cleanedArray(script.text);
                if (isArray(script?.ul)) script.ul = cleanedArray(script?.ul);
                if (isArray(script?.ol)) script.ol = cleanedArray(script?.ol);
                if (isArray(script?.stack)) script.stack = cleanedArray(script?.stack);
                if (isArray(script?.column)) script.column = cleanedArray(script?.column);
                if (isArray(script?.then)) script.then = cleanedArray(script?.then);
                if (isArray(script?.else)) script.else = cleanedArray(script?.else);
                if (isArray(script?.loop)) script.loop = cleanedArray(script?.loop);
                if (isArray(script?.conjunction))
                  script.conjunction = cleanedArray(script?.conjunction);
                return script;
              })
            );
            setFieldValue("manuscript", manuscriptData.manuscript);
          }
          return;
        }

        if (typeof source.data.nodeType === "string")
          target.data.arrayHelpers.insert(finishIndex, getNodeFragment(source.data.nodeType));
      },
    });
  }, [values.manuscript]);

  useEffect(() => {
    const element = ref.current;
    invariant(element);

    return combine(
      dropTargetForElements({
        element,
        onDragLeave: () => {
          setAboutToDrop(false);
        },
        onDragEnter: () => {
          setAboutToDrop(true);
        },
        onDragStart: () => {
          setAboutToDrop(true);
        },
        onDrop: ({ source, self, location }) => {
          setAboutToDrop(false);
        },
      }),
      autoScrollForElements({
        element,
      })
    );
  }, []);

  return (
    <Fragment>
      <Stack
        {...{
          ref,
          sx: {
            backgroundColor: aboutToDrop ? "#f0f0f0" : "white",
            border: "2px dashed",
            borderColor: aboutToDrop ? "primary.main" : "transparent",
            p: 1,
            minHeight: "50vh",
            overflow: "auto",
          },
        }}
      >
        {isEmpty(values.manuscript) ? (
          <EmptyStructurePlaceholder
            {...{
              index: 0,
              name: `manuscript[${0}]`,
              arrayHelpers,
            }}
          />
        ) : (
          Children.toArray(
            map(values.manuscript, (script, SCRIPT_INDEX) => {
              return (
                <Structure
                  {...{
                    index: SCRIPT_INDEX,
                    name: `manuscript[${SCRIPT_INDEX}]`,
                    arrayHelpers,
                    nodeType: getNodeType(script),
                  }}
                />
              );
            })
          )
        )}
      </Stack>
    </Fragment>
  );
});

export default engine(Manuscript);
