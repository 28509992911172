import { jsonCargo } from "@lb/utils";
import { useFormik } from "formik";
import { isArray, isBoolean } from "lodash";
import { useMemo } from "react";

const engine =
  (Component) =>
  ({ ...props }) => {
    const { open, handleSubmit } = props;

    const data = useMemo(() => {
      if (isBoolean(open)) {
        return {};
      } else {
        return jsonCargo.extract(open);
      }
    }, [open]);

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        label: data?.label || "",
        description: data?.description || "",
        selectAll: data?.selectAll || "",
        name: data?.name || "",
        multiple: data?.multiple ?? false,
        options: isArray(data?.options) ? data?.options : [],
        value: data?.value ?? "",
        type: data?.type ?? "",
        min: data?.min ?? "",
        max: data?.max ?? "",
        placeholder: data?.placeholder || "",
      },
      onSubmit: handleSubmit,
    });

    return <Component {...props} {...{ formik, data }} />;
  };

export default engine;
