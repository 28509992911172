import {
  LbNumberField,
  LbPatternField,
  LbSelect,
  LbTextArea,
  LbTextField,
  LbToggleButtons,
} from "@lb/frontend";
import { getInitials } from "@lb/utils";
import { Button, Grid, InputAdornment, Stack } from "@mui/material";
import { Form, useFormikContext } from "formik";
import React, { Fragment } from "react";

function Metadata({}) {
  const { values, setFieldValue } = useFormikContext();
  return (
    <Fragment>
      <Stack height={"100%"} component={Form} mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <LbTextField name="name" label="Template Name" placeholder="Enter template name" />
          </Grid>
          <Grid item md={2}>
            <LbPatternField
              name="abbreviation"
              label="Abbreviation"
              placeholder="Enter abbreviation"
              pattern={"$$$$$$$$$$$$$$"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      disabled={!values?.name}
                      onClick={() => setFieldValue("abbreviation", getInitials(values?.name))}
                      size="small"
                      variant="text"
                      color="info"
                    >
                      Auto
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <LbTextArea
              name="description"
              label="Template description"
              placeholder="Enter template description"
            />
          </Grid>
          <Grid item xs={3}>
            <LbSelect
              name="pageSize"
              label="Page size"
              placeholder="Select page size"
              options={[
                { label: "4A0", value: "4A0" },
                { label: "2A0", value: "2A0" },
                { label: "A0", value: "A0" },
                { label: "A1", value: "A1" },
                { label: "A2", value: "A2" },
                { label: "A3", value: "A3" },
                { label: "A4", value: "A4" },
                { label: "A5", value: "A5" },
                { label: "A6", value: "A6" },
                { label: "A7", value: "A7" },
                { label: "A8", value: "A8" },
                { label: "A9", value: "A9" },
                { label: "A10", value: "A10" },
                { label: "B0", value: "B0" },
                { label: "B1", value: "B1" },
                { label: "B2", value: "B2" },
                { label: "B3", value: "B3" },
                { label: "B4", value: "B4" },
                { label: "B5", value: "B5" },
                { label: "B6", value: "B6" },
                { label: "B7", value: "B7" },
                { label: "B8", value: "B8" },
                { label: "B9", value: "B9" },
                { label: "B10", value: "B10" },
                { label: "C0", value: "C0" },
                { label: "C1", value: "C1" },
                { label: "C2", value: "C2" },
                { label: "C3", value: "C3" },
                { label: "C4", value: "C4" },
                { label: "C5", value: "C5" },
                { label: "C6", value: "C6" },
                { label: "C7", value: "C7" },
                { label: "C8", value: "C8" },
                { label: "C9", value: "C9" },
                { label: "C10", value: "C10" },
                { label: "RA0", value: "RA0" },
                { label: "RA1", value: "RA1" },
                { label: "RA2", value: "RA2" },
                { label: "RA3", value: "RA3" },
                { label: "RA4", value: "RA4" },
                { label: "SRA0", value: "SRA0" },
                { label: "SRA1", value: "SRA1" },
                { label: "SRA2", value: "SRA2" },
                { label: "SRA3", value: "SRA3" },
                { label: "SRA4", value: "SRA4" },
                { label: "EXECUTIVE", value: "EXECUTIVE" },
                { label: "FOLIO", value: "FOLIO" },
                { label: "LEGAL", value: "LEGAL" },
                { label: "LETTER", value: "LETTER" },
                { label: "TABLOID", value: "TABLOID" },
              ]}
            />
          </Grid>

          <Grid item xs={3}>
            <LbSelect
              name="pageOrientation"
              label="Page orientation"
              placeholder="Select page orientation"
              options={[
                { label: "Portrait", value: "portrait" },
                { label: "Landscape", value: "landscape" },
              ]}
            />
          </Grid>

          <Grid item xs={3}>
            <LbNumberField
              name="pageMargins[0]"
              label="Page left margin"
            />
          </Grid>
          
          <Grid item xs={3}>
            <LbNumberField
              name="pageMargins[1]"
              label="Page top margin"
            />
          </Grid>
          
          <Grid item xs={3}>
            <LbNumberField
              name="pageMargins[2]"
              label="Page right margin"
            />
          </Grid>
          
          <Grid item xs={3}>
            <LbNumberField
              name="pageMargins[3]"
              label="Page bottom margin"
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Box
              sx={{
                ".ql-toolbar": { borderRadius: "8px 8px 0 0" },
                ".ql-container": { borderRadius: "0 0 8px 8px" },
                aspectRatio: 1 / 1.4142,
                display: "inline",
              }}
            >
              <Typography color="text.secondary" variant="body2">
                Content:
              </Typography>

              <ReactQuill
                theme="snow"
                value={values.content}
                onChange={(val) => setFieldValue("content", val)}
                style={{ marginTop: 0 }}
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ align: [] }],
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ["table"],
                  ],
                }}
              />
            </Box>
          </Grid> */}
        </Grid>
      </Stack>
    </Fragment>
  );
}

export default Metadata;
