import { LbSmartSelect } from "@lb/frontend";
import { depopulateVariables, EDITOR_NODE_TYPES, getNodeType, VARIABLE_TYPES } from "@lb/utils";
import { Box } from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import { filter, get, isArray, isEmpty, join, map, omit, size, slice, split, times } from "lodash";
import { Children, Fragment, memo, useMemo } from "react";
import { useVariables } from "../../store";
import Structure from "./Structure";
import { vendorVariables } from "../../engine";

const LoopStructure = memo(({ name, nodeData, nodeType, arrayHelpers, index }) => {
  const variables = useVariables((store) => store.variables);
  const { values } = useFormikContext();

  const MMCQ_VARIABLES = useMemo(() => {
    let mmcqVars = [];

    let nodePathArr = split(name, ".");
    times(size(nodePathArr), (pathIndex) => {
      let nestedNodePath = slice(nodePathArr, 0, size(nodePathArr) - pathIndex);
      let nestedNode = get(values, join(nestedNodePath, "."));
      let nestedNodeType = getNodeType(nestedNode);

      if (nestedNodeType === EDITOR_NODE_TYPES.key.LOOP) {
        switch (nestedNode?.source?.name) {
          case "contract.vendors":
            mmcqVars.push(
              ...filter(
                vendorVariables,
                (variable) => variable.type === VARIABLE_TYPES.key.MCQ && variable.multiple
              )
            );
            break;

          default:
            break;
        }
      }
    });

    mmcqVars.push(
      ...filter(
        depopulateVariables(variables),
        (variable) => variable.type === VARIABLE_TYPES.key.MCQ && variable.multiple
      )
    );

    return mmcqVars;
  }, [name, values, variables]);

  return (
    <Fragment>
      <LbSmartSelect
        required
        valueKey="name"
        name={`${name}.source`}
        options={MMCQ_VARIABLES}
        placeholder="Select the variable"
        inputProps={{
          sx: {
            "& .MuiInputBase-root": {
              backgroundColor: "beige",
              fontWeight: nodeData.bold ? "bolder" : "normal",
              fontStyle: nodeData.italics ? "italic" : "normal",
              textDecoration: join(nodeData.decoration, " "),
              "& fieldset": {
                border: 0,
              },
              "& input": {
                textAlign: nodeData.alignment,
                fontWeight: nodeData.bold ? "bolder" : "normal",
                fontStyle: nodeData.italics ? "italic" : "normal",
                textDecoration: join(nodeData.decoration, " "),
              },
            },
          },
        }}
      />

      {/* //! Conjunction Block */}
      {isArray(nodeData?.conjunction) && !isEmpty(nodeData?.conjunction) && (
        <Box pl={"20px"} pt={0.5}>
          <Structure
            {...{
              index,
              name: `${name}`,
              arrayHelpers: arrayHelpers,
              parentAttributes: {},
              parentNodeType: EDITOR_NODE_TYPES.key.LOOP,
              nodeType: EDITOR_NODE_TYPES.key.CONJUNCTION,
            }}
          />
        </Box>
      )}

      <FieldArray name={`${name}.loop`}>
        {(itemArrayHelpers) => {
          return (
            <Box ml={"20px"}>
              {Children.toArray(
                map(nodeData.loop, (item, ITEM_INDEX) => {
                  return (
                    <Structure
                      {...{
                        index: ITEM_INDEX,
                        name: `${name}.loop[${ITEM_INDEX}]`,
                        arrayHelpers: itemArrayHelpers,
                        parentAttributes: omit(nodeData, [EDITOR_NODE_TYPES.key.LOOP]),
                        parentNodeType: nodeType,
                        nodeType: getNodeType(item),
                      }}
                    />
                  );
                })
              )}
            </Box>
          );
        }}
      </FieldArray>
    </Fragment>
  );
});

export default LoopStructure;
