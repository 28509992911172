import { EDITOR_NODE_TYPES, getNodeColor, getNodeFragment } from "@lb/utils";
import { Box, Stack } from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import { get, map, trim, upperCase } from "lodash";
import { Children, Fragment, memo, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { LuGitCommit } from "react-icons/lu";
import Attributes from "./Attributes";
import NodeChip from "./NodeChip";
import RuleStructure from "./Structure.Rule";

const RuleGroupStructure = memo(({ name, removeRuleGroup }) => {
  const { values } = useFormikContext();

  const nodeData = useMemo(() => {
    return get(values, `${name}`) || {};
  }, [name, values]);

  const [configAnchorEl, setConfigAnchorEl] = useState(null);

  const openConfig = (event) => {
    setConfigAnchorEl(event.currentTarget);
  };

  const nodeBadge = useMemo(
    () =>
      `https://img.shields.io/badge/${upperCase(nodeData.conjunction)}-${
        EDITOR_NODE_TYPES._.rules
      }-${trim(getNodeColor(EDITOR_NODE_TYPES.key.RULES), "#")}`,
    [nodeData.conjunction]
  );

  return (
    <Fragment>
      <FieldArray name={`${name}.rules`}>
        {(itemArrayHelpers) => {
          return (
            <Box
              {...{
                component: "fieldset",
                sx: {
                  borderTopLeftRadius: 8,
                  borderWidth: 1.2,
                  borderStyle: "solid",
                  borderColor: getNodeColor(EDITOR_NODE_TYPES.key.RULES),
                  borderRight: 0,
                  borderBottom: 0,
                  pt: 0,
                  pb: 0,
                  pr: 0,
                  m: 0,
                  pl: 0,
                },
              }}
            >
              <Box {...{ component: "legend", width: "100%", p: 0, m: 0 }}>
                <Stack
                  {...{
                    direction: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    spacing: 2,
                    width: "100%",
                    sx: {
                      position: "relative",
                      "&::before": {
                        content: '""',
                        width: "100%",
                        position: "absolute",
                        borderTopWidth: 1.2,
                        borderTopStyle: "solid",
                        borderTopColor: getNodeColor(EDITOR_NODE_TYPES.key.RULES),
                        top: "50%",
                        left: "-20px",
                        transform: "translateY(-50%)",
                      },
                    },
                  }}
                >
                  <Box flex={1} sx={{ zIndex: 0 }}>
                    <img src={nodeBadge} alt={EDITOR_NODE_TYPES.key.RULES} draggable={false} />
                  </Box>

                  <NodeChip
                    label="New Rule"
                    icon={<LuGitCommit />}
                    size="small"
                    variant="outlined"
                    color={"secondary"}
                    onClick={() =>
                      itemArrayHelpers.push(getNodeFragment(EDITOR_NODE_TYPES.key.RULE))
                    }
                  />

                  <NodeChip
                    label="New Rule Group"
                    icon={<LuGitCommit />}
                    size="small"
                    variant="outlined"
                    color={"success"}
                    onClick={() =>
                      itemArrayHelpers.push(getNodeFragment(EDITOR_NODE_TYPES.key.RULES))
                    }
                  />

                  <NodeChip
                    data-node-name={name}
                    data-node-type={EDITOR_NODE_TYPES.key.RULES}
                    label="Config"
                    icon={<LuGitCommit />}
                    size="small"
                    variant="outlined"
                    color={"info"}
                    onClick={openConfig}
                  />

                  {removeRuleGroup && (
                    <NodeChip
                      label="Remove"
                      icon={<LuGitCommit />}
                      size="small"
                      variant="outlined"
                      color={"error"}
                      onClick={removeRuleGroup}
                    />
                  )}
                </Stack>
              </Box>

              <Stack spacing={3} pl={"20px"} py={1.5}>
                {Children.toArray(
                  map(nodeData.rules, (rule, ruleIndex) => {
                    if (rule.rules)
                      return (
                        <RuleGroupStructure
                          {...{ name: `${name}.rules[${ruleIndex}]` }}
                          removeRuleGroup={
                            nodeData.rules?.length > 1
                              ? () => itemArrayHelpers.remove(ruleIndex)
                              : null
                          }
                        />
                      );

                    return (
                      <RuleStructure
                        name={`${name}.rules[${ruleIndex}]`}
                        removeRule={
                          nodeData.rules?.length > 1
                            ? () => itemArrayHelpers.remove(ruleIndex)
                            : null
                        }
                      />
                    );
                  })
                )}
              </Stack>
            </Box>
          );
        }}
      </FieldArray>
      {configAnchorEl &&
        createPortal(<Attributes {...{ setConfigAnchorEl, configAnchorEl }} />, configAnchorEl)}
    </Fragment>
  );
});

export default RuleGroupStructure;
