import {
  attachClosestEdge,
  extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { DropIndicator } from "@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine"; // NEW
import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { Box } from "@mui/material";
import { includes } from "lodash";
import { useEffect, useRef, useState } from "react";
import invariant from "tiny-invariant";

const EmptyStructurePlaceholder = ({ name, index, arrayHelpers, parentNodeType }) => {
  const ref = useRef(null);
  const [closestEdge, setClosestEdge] = useState(null);

  useEffect(() => {
    const element = ref.current;
    invariant(element);
    return combine(
      dropTargetForElements({
        element,
        getData({ input }) {
          return attachClosestEdge(
            { name, index, arrayHelpers },
            {
              element,
              input,
              allowedEdges: ["top", "bottom"],
            }
          );
        },
        onDrag({ self, source, location }) {
          const isSource = source.element === element;
          if (isSource) {
            setClosestEdge(null);
            return;
          }
          const closestEdge = extractClosestEdge(self.data);
          let isDropIndicatorHidden = false;
          isDropIndicatorHidden =
            isDropIndicatorHidden ||
            self.data.name !== location.current.dropTargets[0].data.name ||
            includes(location.current.dropTargets[0].data.name, source.data.name);

          if (isDropIndicatorHidden) {
            setClosestEdge(null);
            return;
          }

          setClosestEdge(closestEdge);
        },
        onDragLeave() {
          setClosestEdge(null);
        },
        onDrop() {
          setClosestEdge(null);
        },
      })
    );
  }, [arrayHelpers, index, name, parentNodeType]);
  return (
    <Box ref={ref} position={"relative"} py={2}>
      <Box position={"relative"}>{closestEdge && <DropIndicator edge={"top"} gap="1px" />}</Box>
    </Box>
  );
};

export default EmptyStructurePlaceholder;
