import { LbLayout, lbUrlNavigator, useClient, useToken } from "@lb/frontend";
import {
  USER_TYPE_MASTER,
  authClientUrls,
  jsonCargo,
  masterClientUrls,
  organizationsClientUrls,
} from "@lb/utils";
import { isEmpty } from "lodash";
import React, { Fragment } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Clauses, {
  AddClause,
  AddEditClauseGroup,
  ClauseGroup,
  EditClause,
} from "./pages/CLM/Clauses";
import Templates, { CreateTemplate, UpdateTemplate } from "./pages/CLM/Templates";
import DRMRepository from "./pages/DRM/Repository";
import Organizations, {
  AddInviteOrganization,
  AddOrganization,
  EditOrganization,
  InviteOrganization,
  OrganizationModules,
} from "./pages/Organizations";
import Nomenclatures from "./pages/Organizations/Nomenclatures";

export default function RoutesProvider() {
  const { token } = useToken();
  const { client: { userType } = {} } = useClient();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={"/"}
          element={
            !isEmpty(token) && userType ? (
              userType !== USER_TYPE_MASTER ? (
                <Fragment>
                  {window.location.replace(lbUrlNavigator(authClientUrls.login.base, "AUTH"))}
                </Fragment>
              ) : (
                <LbLayout />
              )
            ) : (
              <Fragment>
                {window.location.replace(
                  lbUrlNavigator(authClientUrls.logout.base, "AUTH") +
                    `#${jsonCargo.store({ from: window.location.href })}`
                )}
              </Fragment>
            )
          }
        >
          <Route index element={<Navigate to={organizationsClientUrls.base} replace />} />

          <Route path={organizationsClientUrls.slug}>
            <Route path={organizationsClientUrls.base} element={<Organizations />}>
              <Route
                path={organizationsClientUrls.nomenclatures.slug}
                element={<Nomenclatures />}
              />
            </Route>

            <Route element={<AddInviteOrganization />}>
              <Route path={organizationsClientUrls.add.slug} element={<AddOrganization />} />

              <Route path={organizationsClientUrls.invite.slug} element={<InviteOrganization />} />
            </Route>

            <Route path={organizationsClientUrls.modules.slug} element={<OrganizationModules />} />
            <Route path={organizationsClientUrls.edit.slug} element={<EditOrganization />} />
          </Route>

          <Route path={organizationsClientUrls.slug} element={<Organizations />} />

          <Route path={masterClientUrls.clm.slug}>
            <Route path={masterClientUrls.clm.clauses.slug}>
              <Route index element={<Clauses />} />
              <Route path={masterClientUrls.clm.clauses.add.slug} element={<AddClause />} />
              <Route path={masterClientUrls.clm.clauses.edit.slug} element={<EditClause />} />
              <Route path={masterClientUrls.clm.clauses.groups.slug} element={<ClauseGroup />}>
                <Route
                  path={masterClientUrls.clm.clauses.groups.add.slug}
                  element={<AddEditClauseGroup />}
                />
                <Route
                  path={masterClientUrls.clm.clauses.groups.edit.slug}
                  element={<AddEditClauseGroup />}
                />
              </Route>
            </Route>

            <Route path={masterClientUrls.clm.templates.slug}>
              <Route index element={<Templates />} />
              <Route path={masterClientUrls.clm.templates.add.slug} element={<CreateTemplate />} />
              <Route path={masterClientUrls.clm.templates.edit.slug} element={<UpdateTemplate />} />
            </Route>
          </Route>

          <Route path={"drm"}>
            <Route index element={<DRMRepository />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
