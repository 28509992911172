import { LbButton } from "@lb/frontend";
import { Grid, Stack, Toolbar } from "@mui/material";
import { Form, FormikProvider } from "formik";
import React, { Fragment } from "react";
import engine from "./engine";
import PDFOutput from "./PDFOutput";
import TestData from "./TestData";
import WorkPage from "./WorkPage";

function Transcript({
  contractFormik,
  workflows,
  variables,
  manuscript,
  testDataDialog,
  setTestDataDialog,
  values,
}) {
  return (
    <Fragment>
      <Grid container spacing={2} pt={2}>
        <Grid item xs={5} pt={2} position={"relative"}>
          <Stack direction="row" spacing={2} mb={2}>
            <LbButton
              onClick={() => setTestDataDialog(true)}
              sx={{ width: "100%" }}
              color="secondary"
            >
              System Variables
            </LbButton>

            <LbButton
              onClick={contractFormik.handleSubmit}
              disabled={!contractFormik.dirty}
              loading={contractFormik.isSubmitting}
              sx={{ width: "100%" }}
            >
              Persist
            </LbButton>
          </Stack>
          <FormikProvider value={contractFormik}>
            <Form>
              <WorkPage {...{ workflows, variables: values?.variables, name: "workpaper" }} />
            </Form>
          </FormikProvider>

          <Toolbar />
        </Grid>
        <Grid item xs={7}>
          <PDFOutput
            {...{
              manuscript,
              workpaper: contractFormik.values.workpaper,
              contract: contractFormik.values.contract,
              variables,
              pageSize: values.pageSize,
              pageOrientation: values.pageOrientation,
              pageMargins: values.pageMargins,
            }}
          />
        </Grid>
      </Grid>

      <TestData
        open={testDataDialog}
        handleClose={() => setTestDataDialog(false)}
        handleSubmit={(testDataValues) =>
          contractFormik.setFieldValue("workpaper", {
            ...contractFormik.values.workpaper,
            ...testDataValues,
          })
        }
        initialValues={{
          contract: contractFormik.values.workpaper.contract,
          organization: contractFormik.values.workpaper.organization,
        }}
      />
    </Fragment>
  );
}

export default engine(Transcript);
