import {
  attachClosestEdge,
  extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { DropIndicator } from "@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine"; // NEW
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import { LbSmartSelect, useMiscStore } from "@lb/frontend";
import {
  assignNewNodeIdentities,
  CIRCLE_ICON,
  depopulateVariables,
  DISC_ICON,
  EDITOR_NODE_TYPES,
  getNodeColor,
  getNodeFragment,
  getNodeType,
  indexToListStyle,
  lbUid,
  LIST_STYLE_TYPE,
  objectToQueryParams,
  SQUARE_ICON,
  VARIABLE_TYPES,
} from "@lb/utils";
import { Box, Collapse, InputBase, Paper, Stack, Typography } from "@mui/material";
import { Field, FieldArray, useFormikContext } from "formik";
import {
  filter,
  get,
  includes,
  isArray,
  isEmpty,
  join,
  map,
  omit,
  size,
  split,
  trim,
  upperCase,
} from "lodash";
import { Children, Fragment, memo, useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import invariant from "tiny-invariant";
import NodeChip from "./NodeChip";
import { LuGitCommit } from "react-icons/lu";
import { createPortal } from "react-dom";
import Attributes from "./Attributes";
import EmptyStructurePlaceholder from "./Structure.Empty";
import RuleGroupStructure from "./Structure.Rule.Group";
import TextStructure from "./Structure.Text";
import VariableStructure from "./Structure.Variable";
import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import LoopStructure from "./Structure.Loop";

const getNodeBadge = ({ index, nodeType, parentNodeType, parentAttributes, conjunction }) => {
  const nodeColor = trim(getNodeColor(nodeType), "#");
  const parentNodeColor = trim(getNodeColor(parentNodeType), "#");
  const nodeName = `${EDITOR_NODE_TYPES._[nodeType]}`;

  if (parentNodeType === EDITOR_NODE_TYPES.key.UL) {
    return `https://img.shields.io/badge/_-${nodeName}-${nodeColor}.svg?${objectToQueryParams({
      labelColor: parentNodeColor,
      logo: {
        [LIST_STYLE_TYPE.ul.key.DISC]: DISC_ICON,
        [LIST_STYLE_TYPE.ul.key.CIRCLE]: CIRCLE_ICON,
        [LIST_STYLE_TYPE.ul.key.SQUARE]: SQUARE_ICON,
      }[parentAttributes?.type],
    })}`;
  }

  if (parentNodeType === EDITOR_NODE_TYPES.key.OL) {
    return `https://img.shields.io/badge/${indexToListStyle(
      index,
      parentAttributes?.type
    )}-${nodeName}-${nodeColor}?${objectToQueryParams({
      labelColor: parentNodeColor,
    })}`;
  }

  if (nodeType === EDITOR_NODE_TYPES.key.RULES) {
    return `https://img.shields.io/badge/${upperCase(
      conjunction
    )}-${nodeName}-${nodeColor}?${objectToQueryParams({
      labelColor: parentNodeColor,
    })}`;
  }

  return `https://img.shields.io/badge/${nodeName}-${nodeColor}`;
};

const Structure = memo(
  ({ name, index, arrayHelpers, parentAttributes, parentNodeType, nodeType }) => {
    const ref = useRef(null);
    const dragHandleRef = useRef(null);
    const [isDragging, setDragging] = useState(false);
    const [preview, setPreview] = useState(null);
    const { values } = useFormikContext();
    const [closestEdge, setClosestEdge] = useState(null);
    const [configAnchorEl, setConfigAnchorEl] = useState(null);

    const { misc, storeMisc } = useMiscStore();

    const onlyChild = useMemo(
      () => size(get(values, arrayHelpers.name)) < 2,
      [arrayHelpers.name, values]
    );

    const canDrag = useMemo(() => {
      if (
        includes(
          [
            EDITOR_NODE_TYPES.key.THEN,
            EDITOR_NODE_TYPES.key.ELSE,
            EDITOR_NODE_TYPES.key.CONJUNCTION,
          ],
          nodeType
        )
      )
        return false;

      if (onlyChild) return false;

      return true;
    }, [nodeType, onlyChild]);

    const openConfig = (event) => {
      setConfigAnchorEl(event.currentTarget);
    };

    const nodeData = useMemo(() => {
      return get(values, `${name}`) || {};
    }, [name, values]);

    const nodeName = useMemo(() => `${EDITOR_NODE_TYPES._[nodeType]}`, [nodeType]);

    const nodeBadge = useMemo(
      () => getNodeBadge({ index, nodeType, parentNodeType, parentAttributes }),
      [index, nodeType, parentAttributes, parentNodeType]
    );

    const copyId = async () => {
      try {
        await navigator.clipboard.writeText(nodeData.id);
        toast.success(`Node id "${nodeData.id}" copied successfully!`);
      } catch (error) {}
    };

    const duplicate = () => {
      arrayHelpers.insert(index + 1, {
        ...assignNewNodeIdentities(nodeData, nodeType),
        id: lbUid(),
      });
    };

    const MMCQ_VARIABLES = useMemo(
      () =>
        filter(
          depopulateVariables(values.variables),
          (variable) => variable.type === VARIABLE_TYPES.key.MCQ && variable.multiple
        ),
      [values.variables]
    );

    const SHOW_NODE_ID = useMemo(() => {
      //! No need to show nodeID for logical controls
      if (
        includes(
          [
            EDITOR_NODE_TYPES.key.THEN,
            EDITOR_NODE_TYPES.key.CONJUNCTION,
            EDITOR_NODE_TYPES.key.ELSE,
            EDITOR_NODE_TYPES.key.DECISION,
            EDITOR_NODE_TYPES.key.LOOP,
          ],
          nodeType
        )
      )
        return false;

      //! No need to show nodeID for loop childrens as they are dynamically generated
      if (includes(name, "loop")) return false;

      return true;
    }, [name, nodeType]);

    const baseNodeType = useMemo(
      () => getNodeType(get(values, split(name, ".")[0])),
      [name, values]
    );

    useEffect(() => {
      const element = ref.current;
      const dragHandle = dragHandleRef.current;
      invariant(element);
      invariant(dragHandle);
      return combine(
        draggable({
          element,
          dragHandle,
          canDrag: () => canDrag,
          getInitialData: () => ({ name, index, arrayHelpers, nodeType }),
          onDragStart() {
            setDragging(true);
          },
          onDrop() {
            setDragging(false);
            setPreview(null);
          },
          onGenerateDragPreview({ nativeSetDragImage }) {
            setCustomNativeDragPreview({
              nativeSetDragImage,
              render({ container }) {
                setPreview(container);
              },
            });
          },
        }),
        dropTargetForElements({
          element,
          getData({ input }) {
            return attachClosestEdge(
              { name, index, arrayHelpers, nodeType },
              {
                element,
                input,
                allowedEdges: ["top", "bottom"],
              }
            );
          },
          canDrop({ element: targetElement, source, input }) {
            if (parentNodeType === EDITOR_NODE_TYPES.key.DECISION) {
              return false;
            }

            if (nodeType === EDITOR_NODE_TYPES.key.CONJUNCTION) {
              return false;
            }

            if (
              (parentNodeType === EDITOR_NODE_TYPES.key.PARAGRAPH ||
                baseNodeType === EDITOR_NODE_TYPES.key.PARAGRAPH) &&
              nodeType !== EDITOR_NODE_TYPES.key.PARAGRAPH
            ) {
              if (
                includes(
                  [
                    EDITOR_NODE_TYPES.key.TEXT,
                    EDITOR_NODE_TYPES.key.VARIABLE,
                    EDITOR_NODE_TYPES.key.DECISION,
                    EDITOR_NODE_TYPES.key.LOOP,
                  ],
                  source.data.nodeType
                )
              ) {
                return true;
              } else return false;
            }
            return !isDragging;
          },
          onDrag({ self, source, location }) {
            const isSource = source.element === element;
            if (isSource) {
              setClosestEdge(null);
              return;
            }
            const closestEdge = extractClosestEdge(self.data);
            let isDropIndicatorHidden = false;
            isDropIndicatorHidden =
              isDropIndicatorHidden ||
              self.data.name !== location.current.dropTargets[0].data.name ||
              includes(location.current.dropTargets[0].data.name, source.data.name);

            if (isDropIndicatorHidden) {
              setClosestEdge(null);
              return;
            }

            setClosestEdge(closestEdge);
          },
          onDragLeave() {
            setClosestEdge(null);
          },
          onDrop() {
            setClosestEdge(null);
          },
        })
      );
    }, [arrayHelpers, canDrag, index, isDragging, name, nodeType, onlyChild, parentNodeType]);

    const collapseId = useMemo(() => {
      return `${nodeData.id}`;
    }, [nodeData.id]);

    return (
      <Fragment>
        <Box
          ref={ref}
          sx={{
            opacity: isDragging ? 0.2 : 1,
            position: "relative",
            py: 1,
          }}
        >
          <Box
            {...{
              component: "fieldset",
              sx: {
                borderTopLeftRadius: 8,
                borderWidth: 1.2,
                borderStyle: "solid",
                borderColor: getNodeColor(nodeType),
                borderRight: 0,
                borderBottom: 0,
                pt: 0,
                pb: 0,
                pr: 0,
                m: 0,
                pl: 0,
              },
            }}
          >
            <Box {...{ component: "legend", width: "100%", p: 0, m: 0 }}>
              <Stack
                {...{
                  direction: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  spacing: 2,
                  width: "100%",
                  sx: {
                    position: "relative",
                    "&::before": {
                      content: '""',
                      width: "100%",
                      position: "absolute",
                      borderTopWidth: 1.2,
                      borderTopStyle: "solid",
                      borderTopColor: getNodeColor(nodeType),
                      top: "50%",
                      left:
                        size(name.match(/\[\d+\]/g)) > 1 ||
                        includes(
                          [
                            EDITOR_NODE_TYPES.key.THEN,
                            EDITOR_NODE_TYPES.key.ELSE,
                            EDITOR_NODE_TYPES.key.CONJUNCTION,
                          ],
                          nodeType
                        )
                          ? "-20px"
                          : 0,
                      transform: "translateY(-50%)",
                    },
                  },
                }}
              >
                <Box
                  flex={1}
                  ref={dragHandleRef}
                  sx={{ cursor: canDrag ? (isDragging ? "grabbing" : "grab") : "unset", zIndex: 0 }}
                >
                  <img src={nodeBadge} alt={nodeName} draggable={false} />
                </Box>

                {SHOW_NODE_ID && (
                  <NodeChip
                    name={name}
                    label={`#${nodeData.id}`}
                    icon={<LuGitCommit />}
                    size="small"
                    variant={"outlined"}
                    color={isDragging ? "primary" : "default"}
                    onClick={copyId}
                  />
                )}

                {nodeType === EDITOR_NODE_TYPES.key.DECISION &&
                  (!isArray(nodeData?.else) || isEmpty(nodeData?.else)) && (
                    <NodeChip
                      name={name}
                      label={`Add Else`}
                      icon={<LuGitCommit />}
                      size="small"
                      variant={"outlined"}
                      color={isDragging ? "primary" : "default"}
                      onClick={() => {
                        arrayHelpers.form.setFieldValue(`${name}.else`, [
                          getNodeFragment(EDITOR_NODE_TYPES.key.TEXT),
                        ]);
                      }}
                    />
                  )}

                {nodeType === EDITOR_NODE_TYPES.key.LOOP &&
                  (!isArray(nodeData?.conjunction) || isEmpty(nodeData?.conjunction)) && (
                    <NodeChip
                      name={name}
                      label={`Add Conjunction`}
                      icon={<LuGitCommit />}
                      size="small"
                      variant={"outlined"}
                      color={isDragging ? "primary" : "default"}
                      onClick={() => {
                        arrayHelpers.form.setFieldValue(`${name}.conjunction`, [
                          getNodeFragment(EDITOR_NODE_TYPES.key.TEXT),
                        ]);
                      }}
                    />
                  )}

                {!includes(
                  [
                    EDITOR_NODE_TYPES.key.THEN,
                    EDITOR_NODE_TYPES.key.ELSE,
                    EDITOR_NODE_TYPES.key.CONJUNCTION,
                  ],
                  nodeType
                ) && (
                  <NodeChip
                    name={name}
                    label={`Duplicate`}
                    icon={<LuGitCommit />}
                    size="small"
                    variant={"outlined"}
                    color={isDragging ? "primary" : "default"}
                    onClick={duplicate}
                  />
                )}

                {!includes(
                  [
                    EDITOR_NODE_TYPES.key.SPACE,
                    EDITOR_NODE_TYPES.key.THEN,
                    EDITOR_NODE_TYPES.key.CONJUNCTION,
                    EDITOR_NODE_TYPES.key.ELSE,
                  ],
                  nodeType
                ) && (
                  <NodeChip
                    name={name}
                    label={misc[collapseId] ? "Expand" : "Collapse"}
                    icon={<LuGitCommit />}
                    size="small"
                    variant={"outlined"}
                    color={misc[collapseId] ? "success" : "warning"}
                    onClick={() => storeMisc({ [collapseId]: !misc[collapseId] })}
                  />
                )}

                {!includes(
                  [
                    EDITOR_NODE_TYPES.key.SPACE,
                    EDITOR_NODE_TYPES.key.STACK,
                    EDITOR_NODE_TYPES.key.THEN,
                    EDITOR_NODE_TYPES.key.CONJUNCTION,
                    EDITOR_NODE_TYPES.key.ELSE,
                    EDITOR_NODE_TYPES.key.LOOP,
                  ],
                  nodeType
                ) && (
                  <NodeChip
                    data-node-name={name}
                    data-node-type={nodeType}
                    data-parent-node-type={parentNodeType}
                    label="Config"
                    icon={<LuGitCommit />}
                    size="small"
                    variant="outlined"
                    color={"info"}
                    onClick={openConfig}
                  />
                )}

                {((!onlyChild && !includes([EDITOR_NODE_TYPES.key.THEN], nodeType)) ||
                  nodeType === EDITOR_NODE_TYPES.key.CONJUNCTION) && (
                  <NodeChip
                    label="Remove"
                    icon={<LuGitCommit />}
                    size="small"
                    variant="outlined"
                    color={"error"}
                    onClick={() => {
                      switch (nodeType) {
                        case EDITOR_NODE_TYPES.key.CONJUNCTION:
                          arrayHelpers.form.setFieldValue(`${name}.conjunction`, undefined);
                          break;

                        case EDITOR_NODE_TYPES.key.ELSE:
                          arrayHelpers.form.setFieldValue(`${name}.else`, undefined);

                          break;
                        default:
                          arrayHelpers.remove(index);
                          break;
                      }
                    }}
                  />
                )}
              </Stack>
            </Box>

            <Collapse in={!misc[collapseId]} unmountOnExit>
              {!misc[collapseId] &&
                {
                  [EDITOR_NODE_TYPES.key.TEXT]: <TextStructure {...{ name, nodeData }} />,
                  [EDITOR_NODE_TYPES.key.PARAGRAPH]: (
                    <FieldArray name={`${name}.text`}>
                      {(itemArrayHelpers) => {
                        return (
                          <Box ml={"20px"}>
                            {Children.toArray(
                              map(nodeData.text, (item, ITEM_INDEX) => {
                                return (
                                  <Structure
                                    {...{
                                      index: ITEM_INDEX,
                                      name: `${name}.text[${ITEM_INDEX}]`,
                                      arrayHelpers: itemArrayHelpers,
                                      parentAttributes: omit(nodeData, [
                                        EDITOR_NODE_TYPES.key.TEXT,
                                      ]),
                                      parentNodeType: nodeType,
                                      nodeType: getNodeType(item),
                                    }}
                                  />
                                );
                              })
                            )}
                          </Box>
                        );
                      }}
                    </FieldArray>
                  ),
                  [EDITOR_NODE_TYPES.key.OL]: (
                    <FieldArray name={`${name}.ol`}>
                      {(itemArrayHelpers) => {
                        return (
                          <Box ml={"20px"}>
                            {Children.toArray(
                              map(nodeData.ol, (item, ITEM_INDEX) => {
                                return (
                                  <Structure
                                    {...{
                                      index: ITEM_INDEX,
                                      name: `${name}.ol[${ITEM_INDEX}]`,
                                      arrayHelpers: itemArrayHelpers,
                                      parentAttributes: omit(nodeData, [EDITOR_NODE_TYPES.key.OL]),
                                      parentNodeType: nodeType,
                                      nodeType: getNodeType(item),
                                    }}
                                  />
                                );
                              })
                            )}
                          </Box>
                        );
                      }}
                    </FieldArray>
                  ),
                  [EDITOR_NODE_TYPES.key.UL]: (
                    <FieldArray name={`${name}.ul`}>
                      {(itemArrayHelpers) => {
                        return (
                          <Box ml={"20px"}>
                            {Children.toArray(
                              map(nodeData.ul, (item, ITEM_INDEX) => {
                                return (
                                  <Structure
                                    {...{
                                      index: ITEM_INDEX,
                                      name: `${name}.ul[${ITEM_INDEX}]`,
                                      arrayHelpers: itemArrayHelpers,
                                      parentAttributes: omit(nodeData, [EDITOR_NODE_TYPES.key.UL]),
                                      parentNodeType: nodeType,
                                      nodeType: getNodeType(item),
                                    }}
                                  />
                                );
                              })
                            )}
                          </Box>
                        );
                      }}
                    </FieldArray>
                  ),
                  [EDITOR_NODE_TYPES.key.SPACE]: (
                    <Typography sx={{ backgroundColor: "white", px: 1, width: "100%" }}>
                      {"\n"}
                    </Typography>
                  ),
                  [EDITOR_NODE_TYPES.key.COLUMNS]: (
                    <FieldArray name={`${name}.columns`}>
                      {(itemArrayHelpers) => {
                        return (
                          <Box ml={"20px"}>
                            {Children.toArray(
                              map(nodeData.columns, (item, ITEM_INDEX) => {
                                return (
                                  <Structure
                                    {...{
                                      index: ITEM_INDEX,
                                      name: `${name}.columns[${ITEM_INDEX}]`,
                                      arrayHelpers: itemArrayHelpers,
                                      parentAttributes: omit(nodeData, [
                                        EDITOR_NODE_TYPES.key.COLUMNS,
                                      ]),
                                      parentNodeType: nodeType,
                                      nodeType: getNodeType(item),
                                    }}
                                  />
                                );
                              })
                            )}
                          </Box>
                        );
                      }}
                    </FieldArray>
                  ),
                  [EDITOR_NODE_TYPES.key.STACK]: (
                    <FieldArray name={`${name}.stack`}>
                      {(itemArrayHelpers) => {
                        return (
                          <Box ml={"20px"}>
                            {Children.toArray(
                              map(nodeData.stack, (item, ITEM_INDEX) => {
                                return (
                                  <Structure
                                    {...{
                                      index: ITEM_INDEX,
                                      name: `${name}.stack[${ITEM_INDEX}]`,
                                      arrayHelpers: itemArrayHelpers,
                                      parentAttributes: omit(nodeData, [
                                        EDITOR_NODE_TYPES.key.STACK,
                                      ]),
                                      parentNodeType: nodeType,
                                      nodeType: getNodeType(item),
                                    }}
                                  />
                                );
                              })
                            )}
                          </Box>
                        );
                      }}
                    </FieldArray>
                  ),
                  [EDITOR_NODE_TYPES.key.VARIABLE]: (
                    <VariableStructure
                      {...{
                        name,
                        nodeData,

                        index,
                        arrayHelpers,
                        parentAttributes,
                        parentNodeType,
                      }}
                    />
                  ),
                  [EDITOR_NODE_TYPES.key.THEN]: (
                    <FieldArray name={`${name}.then`}>
                      {(itemArrayHelpers) => {
                        return (
                          <Box ml={"20px"}>
                            {Children.toArray(
                              map(nodeData.then, (item, ITEM_INDEX) => {
                                return (
                                  <Structure
                                    {...{
                                      index: ITEM_INDEX,
                                      name: `${name}.then[${ITEM_INDEX}]`,
                                      arrayHelpers: itemArrayHelpers,
                                      parentAttributes: {},
                                      parentNodeType: nodeType,
                                      nodeType: getNodeType(item),
                                    }}
                                  />
                                );
                              })
                            )}
                          </Box>
                        );
                      }}
                    </FieldArray>
                  ),
                  [EDITOR_NODE_TYPES.key.ELSE]: (
                    <FieldArray name={`${name}.else`}>
                      {(itemArrayHelpers) => {
                        return (
                          <Box ml={"20px"}>
                            {isEmpty(nodeData.else) ? (
                              <EmptyStructurePlaceholder
                                {...{
                                  index: 0,
                                  name: `${name}.else[${0}]`,
                                  arrayHelpers: itemArrayHelpers,
                                  parentAttributes: {},
                                  parentNodeType: nodeType,
                                }}
                              />
                            ) : (
                              Children.toArray(
                                map(nodeData.else, (item, ITEM_INDEX) => {
                                  return (
                                    <Structure
                                      {...{
                                        index: ITEM_INDEX,
                                        name: `${name}.else[${ITEM_INDEX}]`,
                                        arrayHelpers: itemArrayHelpers,
                                        parentAttributes: {},
                                        parentNodeType: nodeType,
                                        nodeType: getNodeType(item),
                                      }}
                                    />
                                  );
                                })
                              )
                            )}
                          </Box>
                        );
                      }}
                    </FieldArray>
                  ),
                  [EDITOR_NODE_TYPES.key.DECISION]: (
                    <Box pl={"20px"} pt={0.5}>
                      {/* //! Rules Block */}
                      <RuleGroupStructure name={name} />
                      {/* //! Then Block */}
                      <Structure
                        {...{
                          index,
                          name: `${name}`,
                          arrayHelpers: arrayHelpers,
                          parentAttributes: {},
                          parentNodeType: EDITOR_NODE_TYPES.key.DECISION,
                          nodeType: EDITOR_NODE_TYPES.key.THEN,
                        }}
                      />
                      {/* //! Else Block */}
                      {isArray(nodeData?.else) && !isEmpty(nodeData?.else) && (
                        <Structure
                          {...{
                            index,
                            name: `${name}`,
                            arrayHelpers: arrayHelpers,
                            parentAttributes: {},
                            parentNodeType: EDITOR_NODE_TYPES.key.DECISION,
                            nodeType: EDITOR_NODE_TYPES.key.ELSE,
                          }}
                        />
                      )}
                    </Box>
                  ),
                  [EDITOR_NODE_TYPES.key.CONJUNCTION]: (
                    <FieldArray name={`${name}.conjunction`}>
                      {(itemArrayHelpers) => {
                        return (
                          <Box ml={"20px"}>
                            {Children.toArray(
                              map(nodeData.conjunction, (item, ITEM_INDEX) => {
                                return (
                                  <Structure
                                    {...{
                                      index: ITEM_INDEX,
                                      name: `${name}.conjunction[${ITEM_INDEX}]`,
                                      arrayHelpers: itemArrayHelpers,
                                      parentAttributes: {},
                                      parentNodeType: nodeType,
                                      nodeType: getNodeType(item),
                                    }}
                                  />
                                );
                              })
                            )}
                          </Box>
                        );
                      }}
                    </FieldArray>
                  ),
                  [EDITOR_NODE_TYPES.key.LOOP]: (
                    <LoopStructure {...{ nodeData, nodeType, name, index, arrayHelpers }} />
                  ),
                }[nodeType]}
            </Collapse>
          </Box>

          {closestEdge && <DropIndicator edge={closestEdge} gap="1px" />}
        </Box>
        {preview &&
          createPortal(
            <Paper
              sx={{ py: 0.5, px: 2, border: "2px solid", borderColor: "primary.main", opacity: 1 }}
            >
              <Typography color="primary.main" m={0} variant="body2">
                {nodeName}
              </Typography>
            </Paper>,
            preview
          )}

        {configAnchorEl &&
          createPortal(<Attributes {...{ setConfigAnchorEl, configAnchorEl }} />, configAnchorEl)}
      </Fragment>
    );
  }
);

export default Structure;
