import { LbButton, LbPopper, LbTabs, LbTitle } from "@lb/frontend";
import { masterClientUrls } from "@lb/utils";
import { InfoOutlined } from "@mui/icons-material";
import { Badge, Divider, IconButton, Stack, Toolbar, Typography, useTheme } from "@mui/material";
import { FormikProvider } from "formik";
import { compact, isEmpty, map, size, toPairs, values } from "lodash";
import React, { Children, createElement, Fragment } from "react";
import engine from "./engine";
import Manuscript from "./Manuscript";
import Metadata from "./Metadata";
import Transcript from "./Transcript";
import Workpaper from "./Workpaper";
import Variables from "./Variables";

function Update({
  step,
  previousStep,
  nextStep,
  data,
  handleSubmit,
  STEP_OPTIONS,
  searchParams,
  setSearchParams,
  formik,
  errors,
}) {
  const theme = useTheme();
  return (
    <div>
      <Stack direction="row" alignItems={"center"} justifyContent="space-between" spacing={2}>
        <LbTitle link={masterClientUrls.clm.templates.base} tooltip="Back to templates list">
          Edit template: {data?.name || data?.template?.name}
        </LbTitle>
        {!isEmpty(errors) && (
          <LbPopper
            popperProps={{
              sx: {
                zIndex: theme.zIndex.appBar + 1,
              },
            }}
            content={
              <Fragment>
                <Typography color="error" mb={1} fontFamily={"courier"} fontWeight={"bolder"}>
                  Errors:
                </Typography>
                <Divider />
                <table>
                  {Children.toArray(
                    map(toPairs(errors), ([field, error]) => {
                      if (isEmpty(error)) return null;
                      return (
                        <tr>
                          <td>
                            <Typography
                              variant="overline"
                              color="text.secondary"
                              fontWeight={"bolder"}
                              fontFamily={"courier"}
                            >
                              {field}:
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              variant="overline"
                              color="error"
                              fontWeight={"bolder"}
                              fontFamily={"courier"}
                            >
                              {error}
                            </Typography>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </table>
              </Fragment>
            }
          >
            <IconButton color="error">
              <Badge
                badgeContent={size(compact(values(errors)))}
                color="error"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <InfoOutlined />
              </Badge>
            </IconButton>
          </LbPopper>
        )}
        <LbButton
          size="small"
          type="button"
          color="error"
          disabled={formik?.isSubmitting || !formik.dirty}
          onClick={formik?.resetForm}
        >
          Reset
        </LbButton>
        <LbButton
          size="small"
          type="submit"
          loading={formik.isSubmitting}
          disabled={!formik.dirty || formik.isValidating}
          onClick={formik.handleSubmit}
        >
          Save
        </LbButton>
      </Stack>
      <LbTabs
        activeTab={step.selected}
        handleTabChange={step.select}
        tabs={STEP_OPTIONS.labelValue}
      />
      <Divider />

      <FormikProvider value={formik}>
        {createElement(
          {
            metadata: Metadata,
            variables: Variables,
            workpaper: Workpaper,
            transcript: Transcript,
            manuscript: Manuscript,
          }[step.selected] || Metadata,
          {
            data,
            handleSubmit,
            searchParams,
            setSearchParams,
          }
        )}
      </FormikProvider>
    </div>
  );
}

export default engine(Update);
