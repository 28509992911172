import {
  countryEndpoints,
  getFlag,
  LbActionFooter,
  LbAddress,
  LbAutocomplete,
  LbButton,
  LbPatternField,
  LbSearch,
  LbSelect,
  LbTextField,
  YupObjSchema,
} from "@lb/frontend";
import {
  ALPHABET_REGEX,
  BUSINESS_CATEGORIES,
  BUSINESS_TYPES,
  getInitials,
  INDUSTRY_OPTIONS,
  ORG_STRUCTURE,
  ORG_TYPES,
  OTHER_ADDRESS_FRAGMENT_STRUCTURE,
  PROPERTY_TYPES,
  CONTACT_NUMBER_PATTERN,
} from "@lb/utils";
import { Close } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CardActionArea,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { FieldArray, Formik } from "formik";
import { keys, map, omit, pick } from "lodash";
import React, { Children, Fragment } from "react";
import { HiOutlinePlus } from "react-icons/hi";
import * as Yup from "yup";

function MetaData({ data, submitHandler, organizationId, nextStep }) {
  const theme = useTheme();

  return (
    <div>
      <Formik
        initialValues={pick(
          data,
          keys(
            omit(
              {
                ...ORG_STRUCTURE.METADATA,
                adminName: "",
                adminEmail: "",
                administrativeDepartmentName: "",
                administrativeDepartmentDescription: "",
              },
              [
                ...(organizationId
                  ? [
                      "adminName",
                      "adminEmail",
                      "administrativeDepartmentName",
                      "administrativeDepartmentDescription",
                    ]
                  : ["admin", "administrativeDepartment"]),
              ]
            )
          )
        )}
        validationSchema={Yup.object({
          name: Yup.string().required("Required"),
          email: Yup.string().required("Required").email("Invalid Email"),
          orgType: Yup.string().required("Required"),
          businessType: Yup.string().required("Required"),
          dateFormat: Yup.string().required("Required"),
          businessCategory: Yup.string().required("Required"),
          industry: Yup.string().required("Required"),
          salesRepresentative: Yup.string()
            .required("Required")
            .matches(ALPHABET_REGEX, "Invalid Format"),
          location: Yup.object().shape({
            country: Yup.mixed().required("Required"),
            address: YupObjSchema("placeId"),
          }),
          otherAddress: Yup.array().of(
            Yup.object().shape({
              propertyType: Yup.string().required("Required"),
              address: Yup.object().shape({
                placeId: Yup.string().required(),
              }),
            })
          ),
          adminName: Yup.string().when([], {
            is: () => !organizationId,
            then: (schema) => schema.required("Required"),
          }),
          adminEmail: Yup.string().when([], {
            is: () => !organizationId,
            then: (schema) => schema.required("Required").email("Invalid Email"),
          }),
          administrativeDepartmentName: Yup.string().when([], {
            is: () => !organizationId,
            then: (schema) => schema.required("Required"),
          }),
        })}
        onSubmit={submitHandler}
      >
        {({ handleSubmit, values, isSubmitting, dirty, isValid, setFieldValue, errors }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container rowSpacing={4} columnSpacing={3}>
                <Grid item md={3}>
                  <LbTextField
                    required
                    name="name"
                    label="Organization Name"
                    placeholder="Enter organization name"
                  />
                </Grid>
                <Grid item md={3}>
                  <LbPatternField
                    name="abbreviation"
                    label="Abbreviation"
                    placeholder="Enter abbreviation"
                    pattern={"$$$"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            disabled={!values?.name}
                            onClick={() => setFieldValue("abbreviation", getInitials(values?.name))}
                            size="small"
                            variant="text"
                            color="info"
                          >
                            Auto
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <LbTextField
                    required
                    name="email"
                    label="Organization Email"
                    placeholder="Enter Organization Email"
                  />
                </Grid>
                <Grid item md={3}>
                  <LbSearch
                    required
                    name="location.country"
                    label="Country"
                    optionLabel={"name"}
                    queryParamName="search"
                    placeholder="Search country"
                    requestUrl={countryEndpoints.base}
                    baseUrl="auth"
                    callback={(newVal) =>
                      newVal?.code !== values.location?.country?.code &&
                      setFieldValue("location.address", null)
                    }
                    additionalProperties={["code", "flag", "phoneCode"]}
                    InputProps={
                      values.location?.country?.flag && {
                        startAdornment: (
                          <InputAdornment position="start">
                            <Avatar
                              src={getFlag(values.location?.country?.code)}
                              variant="square"
                              sx={{
                                height: "20px",
                                width: "30px",
                                border: 1,
                                ml: 0.5,
                              }}
                            />
                          </InputAdornment>
                        ),
                      }
                    }
                    renderOption={(optionProps, option) => {
                      return (
                        <ListItem {...optionProps} dense>
                          <ListItemAvatar sx={{ minWidth: 0, pr: 2 }}>
                            <Avatar
                              src={getFlag(option?.code)}
                              variant="square"
                              sx={{ height: 32, width: 32, border: 1, borderColor: "grey.300" }}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={option?.name}
                            secondary={option?.code}
                          ></ListItemText>
                        </ListItem>
                      );
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <LbPatternField
                    name="contactNo"
                    label="Contact Number"
                    placeholder="Enter contact number"
                    pattern={CONTACT_NUMBER_PATTERN}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {values.location?.country?.phoneCode}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={3}>
                  <LbSelect
                    required
                    name="orgType"
                    label="Organization Type"
                    options={ORG_TYPES.labelValue}
                    placeholder="Select Organization Type"
                  />
                </Grid>
                <Grid item md={3}>
                  <LbSelect
                    required
                    name="businessType"
                    label="Business Type"
                    options={BUSINESS_TYPES.labelValue}
                    placeholder="Select Business Type"
                  />
                </Grid>
                <Grid item md={3}>
                  <LbSelect
                    required
                    name="businessCategory"
                    label="Business Category"
                    options={BUSINESS_CATEGORIES.labelValue}
                    placeholder="Select business category"
                  />
                </Grid>
                <Grid item md={3}>
                  <LbAutocomplete
                    required
                    name="industry"
                    options={INDUSTRY_OPTIONS.options}
                    placeholder="Select industry"
                    label="Industry"
                  />
                </Grid>
                <Grid item md={3}>
                  <LbPatternField
                    name="turnover"
                    label="Annual turnover"
                    placeholder="Enter annual turnover"
                    pattern="0000000000000000000"
                  />
                </Grid>
                <Grid item md={3}>
                  <LbPatternField
                    name="teamSize"
                    label="Team Size"
                    placeholder="Enter Team Size"
                    pattern="0000000000000000000"
                  />
                </Grid>
                <Grid item md={3}>
                  <LbTextField name="website" label="Website" placeholder="Enter website url" />
                </Grid>
                {!organizationId && (
                  <Fragment>
                    <Grid item md={3}>
                      <LbTextField
                        required
                        name="adminName"
                        label="Admin Name"
                        placeholder="Enter Admin Name"
                      />
                    </Grid>

                    <Grid item md={3}>
                      <LbTextField
                        required
                        name="adminEmail"
                        label="Admin Email"
                        placeholder="Enter Admin Email"
                      />
                    </Grid>
                  </Fragment>
                )}
                {!organizationId && (
                  <Fragment>
                    <Grid item md={3}>
                      <LbTextField
                        required
                        name="administrativeDepartmentName"
                        label="Administrative Department Name"
                        placeholder="Enter Department Name"
                      />
                    </Grid>

                    <Grid item md={6}>
                      <LbTextField
                        required
                        name="administrativeDepartmentDescription"
                        label="Administrative Department Description"
                        placeholder="Enter Administrative Department Description"
                      />
                    </Grid>
                  </Fragment>
                )}
                <Grid item md={3}>
                  <LbTextField
                    required
                    name="salesRepresentative"
                    label="Sales Representative"
                    placeholder="Enter sales representative"
                  />
                </Grid>
                <Grid item md={6}>
                  <LbAddress
                    required
                    name="location.address"
                    label="Organization Address"
                    placeholder="Enter organization address"
                    queryParams={{ country: values.location?.country?.code }}
                  />
                </Grid>
                <Grid item md={3}>
                  <LbTextField
                    required
                    name="dateFormat"
                    label="Date Format"
                    placeholder="Enter date format"
                  />
                </Grid>
              </Grid>

              <FieldArray name="otherAddresses">
                {({ push, remove }) => {
                  return (
                    <Box mt={3}>
                      <Typography
                        component={"legend"}
                        color="GrayText"
                        px={0.7}
                        ml={-0.5}
                        pb={0.2}
                        mb={2}
                      >
                        Other Addresses
                      </Typography>

                      <Grid container columnSpacing={3}>
                        {Children.toArray(
                          map(values?.otherAddresses, (item, index) => {
                            return (
                              <Grid item md={4} mb={3}>
                                <fieldset
                                  style={{
                                    borderRadius: "8px",
                                    borderColor: theme.palette.divider,
                                    position: "relative",
                                  }}
                                >
                                  <Typography
                                    component={"legend"}
                                    variant="caption"
                                    px={0.7}
                                    ml={-0.5}
                                    pb={0.2}
                                    color={"#333"}
                                  >
                                    Address {index + 1}
                                  </Typography>

                                  <IconButton
                                    onClick={() => {
                                      remove(index);
                                    }}
                                    sx={{
                                      position: "absolute",
                                      top: theme.spacing(-3.7),
                                      right: "4%",
                                      backgroundColor: "#fff!important",
                                      border: 1,
                                      zIndex: 1,
                                      borderColor: theme.palette.divider,
                                      borderWidth: "2px",
                                    }}
                                    size="small"
                                    color="error"
                                  >
                                    <Close fontSize="small" />
                                  </IconButton>
                                  <LbSelect
                                    name={`otherAddresses.[${index}].propertyType`}
                                    placeholder="Select property type"
                                    label="Property Type"
                                    boxProps={{ py: 1.5 }}
                                    options={PROPERTY_TYPES.labelValue}
                                  />
                                  <LbAddress
                                    name={`otherAddresses.[${index}].address`}
                                    placeholder="Enter address"
                                    label="Address"
                                    boxProps={{ py: 1.5 }}
                                  />
                                </fieldset>
                              </Grid>
                            );
                          })
                        )}

                        <Grid item md={4} mb={3}>
                          <fieldset
                            style={{
                              height: "100%",
                              width: "100%",
                              borderRadius: "8px",
                              borderColor: theme.palette.divider,
                              position: "relative",
                              borderStyle: "dashed",
                              boxSizing: "border-box",
                            }}
                          >
                            <Typography
                              component={"legend"}
                              variant="caption"
                              px={0.7}
                              ml={-0.5}
                              pb={0.2}
                              color={"#333"}
                            >
                              Address {values?.otherAddresses?.length + 1 || 0 + 1}
                            </Typography>
                            <CardActionArea
                              sx={{ height: "100%", borderRadius: "8px" }}
                              onClick={() => push(OTHER_ADDRESS_FRAGMENT_STRUCTURE)}
                            >
                              <Stack
                                color={"divider"}
                                fontSize={"100px"}
                                alignItems="center"
                                justifyContent={"center"}
                                height="100%"
                                sx={{
                                  "&:hover": {
                                    color: "primary.light",
                                  },
                                }}
                              >
                                <HiOutlinePlus />
                              </Stack>
                            </CardActionArea>
                          </fieldset>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                }}
              </FieldArray>
              <Toolbar />
              <LbActionFooter>
                <LbButton
                  type={organizationId ? (dirty || !isValid ? "submit" : "button") : "submit"}
                  loading={isSubmitting}
                  onClick={() => (dirty || !isValid ? handleSubmit : nextStep?.())}
                >
                  {organizationId ? (dirty || !isValid ? "Update" : "Next") : "Submit"}
                </LbButton>
              </LbActionFooter>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

export default MetaData;
