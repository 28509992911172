import { LbButton, LbChip, LbDataTable, LbMoreMenu } from "@lb/frontend";
import { BUSINESS_CATEGORIES, BUSINESS_TYPES, ORG_TYPES, masterClientUrls } from "@lb/utils";
import { Visibility } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { map } from "lodash";
import React, { Children, Fragment } from "react";
import { FiEdit3 } from "react-icons/fi";
import { Link, generatePath } from "react-router-dom";
import engine from "./engine";

function Clauses({ data, isLoading, setSearchVal, pagination, setPagination, organization }) {
  return (
    <Fragment>
      <LbDataTable
        title="Clauses"
        {...{ pagination, isLoading, setSearchVal, setPagination }}
        height={"100%"}
        data={map(data, ({ names, businessTypes, businessCategories, orgTypes, _id } = {}) => [
          names,
          businessTypes,
          businessCategories,
          orgTypes,
          _id,
        ])}
        columns={[
          {
            name: "names",
            label: "Name",
            options: {
              customBodyRender: (value) => {
                return (
                  <Stack direction={"row"} rowGap={1} columnGap={1} flexWrap={"wrap"}>
                    {Children.toArray(map(value, (item) => <LbChip label={value}></LbChip>))}
                  </Stack>
                );
              },
            },
          },
          {
            name: "businessTypes",
            label: "Business Type",
            options: {
              customBodyRender: (value) => {
                return (
                  <Stack direction={"row"} rowGap={1} columnGap={1} flexWrap={"wrap"}>
                    {Children.toArray(
                      map(value, (item) => <LbChip label={BUSINESS_TYPES._[item]}></LbChip>)
                    )}
                  </Stack>
                );
              },
            },
          },
          {
            name: "businessCategories",
            label: "Business Category",
            options: {
              customBodyRender: (value) => {
                return (
                  <Stack direction={"row"} rowGap={1} columnGap={1} flexWrap={"wrap"}>
                    {Children.toArray(
                      map(value, (item) => <LbChip label={BUSINESS_CATEGORIES._[item]}></LbChip>)
                    )}
                  </Stack>
                );
              },
            },
          },
          {
            name: "orgTypes",
            label: "Organization Type",
            options: {
              customBodyRender: (value) => {
                return (
                  <Stack direction={"row"} rowGap={1} columnGap={1} flexWrap={"wrap"}>
                    {Children.toArray(
                      map(value, (item) => <LbChip label={ORG_TYPES._[item]}></LbChip>)
                    )}
                  </Stack>
                );
              },
            },
          },
          {
            name: "action",
            label: "Action",
            options: {
              filter: false,
              sort: false,
              print: false,
              download: false,
              customBodyRender: (_id) => {
                return (
                  <LbMoreMenu
                    menus={[
                      {
                        label: "Edit",
                        icon: FiEdit3,
                        path: generatePath(masterClientUrls.clm.clauses.edit.base, {
                          clauseId: _id,
                          orgId: organization._id || "public",
                        }),
                      },
                      {
                        label: "View",
                        icon: Visibility,
                        path: generatePath(masterClientUrls.clm.clauses.base),
                      },
                    ]}
                  />
                );
              },
              setCellProps: (value) => {
                return {
                  align: "center",
                };
              },
              setCellHeaderProps: (value) => {
                return {
                  align: "center",
                };
              },
            },
          },
        ]}
        options={{
          setTableProps: () => {
            return {
              size: "small",
            };
          },
          customToolbar: ({ displayData }) => {
            return (
              <LbButton
                sx={{ order: -1 }}
                variant="outlined"
                component={Link}
                to={masterClientUrls.clm.clauses.add.base}
              >
                Add/ Create
              </LbButton>
            );
          },
        }}
      />
    </Fragment>
  );
}

export default engine(Clauses);
export { default as AddClause } from "./Add";
export { default as EditClause } from "./Edit";
export * from "./Groups";
export { default as ClauseGroup } from "./Groups";
