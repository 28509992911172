import { Chip, styled } from "@mui/material";

const NodeChip = styled(Chip)(({ onClick }) => ({
  display: "flex",
  alignItems: "center",
  width: "fit-content",
  cursor: onClick ? "pointer" : "default",
  zIndex: 0.5,
  position: "relative",
  "&.MuiChip-sizeSmall": {
    fontSize: "12px",
    height: "fit-content",
  },
  "& .MuiChip-iconSmall": {
    fontSize: "12px",
  },
  borderWidth: 1.5,
  "&.MuiChip-outlined": {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white!important",
    },
  },
}));

export default NodeChip;
