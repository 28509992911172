import React, { Fragment } from "react";
import engine from "./engine";
import {
  contractsTemplatesEndpoints,
  LbActionFooter,
  LbButton,
  LbSearch,
  LbTitle,
  orgEndpoints,
} from "@lb/frontend";
import { AppBar, Drawer, ListItem, ListItemText, Stack, Toolbar } from "@mui/material";
import { Close } from "@mui/icons-material";
import { FormikProvider } from "formik";

function ImportTemplate({ open, handleClose, formik }) {
  return (
    <Fragment>
      <Drawer
        open={open}
        anchor="right"
        onClose={handleClose}
        PaperProps={{ sx: { width: { xs: "100%", md: "50%", lg: "35%" } } }}
      >
        <Stack height="100%">
          <AppBar
            position="sticky"
            elevation={0}
            sx={{
              backgroundColor: "#ECF6FE",
            }}
          >
            <Toolbar variant="dense" sx={{ px: "1rem!important" }}>
              <LbTitle
                backAction={handleClose}
                icon={<Close color="primary" />}
                iconRight
                typoProps={{ color: "primary.main" }}
                tooltip="Back to variables list"
              >
                Import Variables
              </LbTitle>
            </Toolbar>
          </AppBar>

          <FormikProvider value={formik}>
            <Stack spacing={3.5} px={2} pt={2} pb={10} overflow={"scroll"}>
              <LbSearch
                name="organization"
                label="Organization"
                optionLabel="name"
                queryParamName="search"
                placeholder="Search organizations"
                requestUrl={orgEndpoints.search}
                baseUrl="master"
              />

              <LbSearch
                key={formik.values?.organization?._id || "public"}
                required
                name="template"
                optionLabel="name"
                label="Contract Template"
                queryParamName="search"
                placeholder="Search contract template"
                requestUrl={contractsTemplatesEndpoints.search(formik.values?.organization?._id)}
                baseUrl="clm"
                descriptionLabel="description"
                renderOption={(optionProps, option) => {
                  return (
                    <ListItem {...optionProps}>
                      <ListItemText
                        primary={option?.name}
                        secondary={option?.description}
                        secondaryTypographyProps={{
                          noWrap: true,
                        }}
                      ></ListItemText>
                    </ListItem>
                  );
                }}
              />
            </Stack>
          </FormikProvider>

          <LbActionFooter
            sx={{
              mt: "auto!important",
              position: "sticky",
              bottom: 0,
              justifyContent: "space-between",
            }}
          >
            <LbButton
              type="button"
              disabled={formik?.isSubmitting}
              color="warning"
              onClick={handleClose}
            >
              Cancel
            </LbButton>

            <Stack direction="row" justifyContent={"end"} spacing={2}>
              <LbButton
                color="error"
                type="button"
                disabled={formik?.isSubmitting || !formik?.dirty}
                onClick={formik?.resetForm}
                hidden={true}
              >
                Reset
              </LbButton>
              <LbButton
                type="submit"
                onClick={formik?.handleSubmit}
                loading={formik?.isSubmitting}
                disabled={!formik?.dirty}
              >
                Import
              </LbButton>
            </Stack>
          </LbActionFooter>
        </Stack>
      </Drawer>
    </Fragment>
  );
}

export default engine(ImportTemplate);
