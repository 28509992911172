import { LbNumberField, LbSmartSelect, LbTextField } from "@lb/frontend";
import {
  depopulateVariables,
  EDITOR_NODE_TYPES,
  getNodeColor,
  getNodeType,
  OPERATORS,
  VARIABLE_TYPES,
} from "@lb/utils";
import { Box, Stack } from "@mui/material";
import { useFormikContext } from "formik";
import {
  find,
  get,
  has,
  includes,
  isArray,
  isEmpty,
  isNumber,
  join,
  map,
  pick,
  size,
  slice,
  split,
  times,
  upperCase,
} from "lodash";
import { Fragment, memo, useMemo } from "react";
import NodeChip from "./NodeChip";
import { LuGitCommit } from "react-icons/lu";
import {
  organizationSignatoryVariables,
  vendorSignatoryVariables,
  vendorVariables,
} from "../../engine";
import { useVariables } from "../../store";

const RuleStructure = memo(({ name, removeRule }) => {
  const { values, setFieldValue } = useFormikContext();
  const variables = useVariables((store) => store.variables);

  const SOURCE_VARIABLES = useMemo(() => {
    let variablesList = [];

    let nodePathArr = split(name, ".");
    times(size(nodePathArr), (pathIndex) => {
      let nestedNodePath = slice(nodePathArr, 0, size(nodePathArr) - pathIndex);
      let nestedNode = get(values, join(nestedNodePath, "."));
      let nestedNodeType = getNodeType(nestedNode);

      if (nestedNodeType === EDITOR_NODE_TYPES.key.LOOP) {
        switch (nestedNode?.source?.name) {
          case "contract.vendors":
            variablesList.push(...vendorVariables);
            break;

          case "organization.signatories":
            variablesList.push(...organizationSignatoryVariables);
            break;

          case "contract.vendors[n].signatories":
            variablesList.push(...vendorSignatoryVariables);
            break;

          default:
            variablesList.push({
              label: `${nestedNode?.source?.label} [nth]`,
              name: `${nestedNode?.source?.name}[n]`,
              type: VARIABLE_TYPES.key.TEXT,
            });
            break;
        }
      }
    });

    if (!isEmpty(variables)) variablesList.push(...variables);

    return variablesList;
  }, [name, variables, values]);

  // const variablesList = useMemo(
  //   () => depopulateVariables(values.variables) || [],
  //   [values.variables]
  // );

  // const VARIABLE_LIST_OPTIONS = useMemo(
  //   () => map(variablesList, (v) => pick(v, ["label", "name"])) || [],
  //   [variablesList]
  // );

  const selectedSourceVar = useMemo(
    () => find(SOURCE_VARIABLES, { name: get(values, `${name}.source.name`) }) || {},
    [name, values, SOURCE_VARIABLES]
  );

  const variableResponseOptions = useMemo(
    () => map(selectedSourceVar?.options, (o) => ({ label: o, value: o })),
    [selectedSourceVar?.options]
  );

  const operatorOptions = useMemo(() => {
    return (
      {
        [VARIABLE_TYPES.key.MCQ]: selectedSourceVar?.multiple
          ? OPERATORS.mmcq.labelValue
          : OPERATORS.mcq.labelValue,
        [VARIABLE_TYPES.key.TEXT]: OPERATORS.text.labelValue,
        [VARIABLE_TYPES.key.STATIC]: OPERATORS.text.labelValue,
        [VARIABLE_TYPES.key.NUMBER]: OPERATORS.number.labelValue,
      }[selectedSourceVar?.type] || OPERATORS.all.labelValue
    );
  }, [selectedSourceVar]);

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        position: "relative",
        "&::before": {
          content: '""',
          width: "100%",
          position: "absolute",
          borderTopWidth: 1.2,
          borderTopStyle: "solid",
          borderTopColor: getNodeColor(EDITOR_NODE_TYPES.key.RULES),
          top: "20px",
          left: "-20px",
          zIndex: 0,
        },
      }}
    >
      <LbSmartSelect
        placeholder="Select variable"
        required
        name={`${name}.source`}
        label="Variable"
        options={SOURCE_VARIABLES}
        valueKey="name"
        callback={(selectedVariable) => {
          setFieldValue(`${name}.value`, "");

          const sv = find(values.variables, { name: selectedVariable?.name });
          const so = get(values, `${name}.operator`)?.value;

          if (isEmpty(!sv)) {
            if (sv?.type === VARIABLE_TYPES.key.MCQ && sv?.multiple) {
              if (!includes(OPERATORS.mmcq.keys, so)) setFieldValue(`${name}.operator`, "");
            }
            if (sv?.type === VARIABLE_TYPES.key.MCQ && !sv?.multiple) {
              if (!includes(OPERATORS.mcq.keys, so)) setFieldValue(`${name}.operator`, "");
            }
          }

          if (sv?.type === VARIABLE_TYPES.key.NUMBER) {
            if (!includes(OPERATORS.number.keys, so)) setFieldValue(`${name}.operator`, "");
          }

          if (sv?.type === VARIABLE_TYPES.key.TEXT || sv?.type === VARIABLE_TYPES.key.STATIC) {
            if (!includes(OPERATORS.text.keys, so)) setFieldValue(`${name}.operator`, "");
          }
        }}
        inputProps={{
          sx: {
            "& .MuiInputBase-root": {
              backgroundColor: "beige",
            },
          },
        }}
      />

      <LbSmartSelect
        required
        placeholder="Select operator"
        name={`${name}.operator`}
        label={`${upperCase(selectedSourceVar?.type?.value || selectedSourceVar?.type)} Operator`}
        disabled={isEmpty(get(values, `${name}.source`))}
        options={operatorOptions}
        callback={(selected) => {
          if (isEmpty(!selectedSourceVar)) {
            const variableValue = get(values, `${name}.value`);

            if (selectedSourceVar?.type === VARIABLE_TYPES.key.MCQ && selectedSourceVar?.multiple) {
              if (!has(variableValue, [0, "label"])) {
                setFieldValue(`${name}.value`, "");
              }
            }

            if (
              selectedSourceVar?.type === VARIABLE_TYPES.key.MCQ &&
              !selectedSourceVar?.multiple
            ) {
              switch (selected?.value) {
                case OPERATORS.all.key.EQUALS:
                  if (!has(variableValue, ["label"])) setFieldValue(`${name}.value`, "");
                  break;
                case OPERATORS.all.key.NOT_EQUALS:
                  if (!has(variableValue, ["label"])) setFieldValue(`${name}.value`, "");
                  break;
                case OPERATORS.all.key.IN:
                  if (!has(variableValue, [0, "label"])) setFieldValue(`${name}.value`, "");
                  break;
                case OPERATORS.all.key.NOT_IN:
                  if (!has(variableValue, [0, "label"])) setFieldValue(`${name}.value`, "");
                  break;
                case OPERATORS.all.key.EXISTS:
                  setFieldValue(`${name}.value`, "");
                  break;
                default:
                  break;
              }
            }

            if (selectedSourceVar?.type === VARIABLE_TYPES.key.NUMBER) {
              if (
                includes(
                  [OPERATORS.all.key.NOT_BETWEEN, OPERATORS.all.key.BETWEEN],
                  selected?.value
                )
              ) {
                if (!isArray(variableValue)) {
                  setFieldValue(`${name}.value`, ["", ""]);
                }
              } else {
                if (!isNumber(variableValue)) {
                  setFieldValue(`${name}.value`, "");
                }
              }
            }
          }
        }}
        inputProps={{
          sx: {
            "& .MuiInputBase-root": {
              backgroundColor: "beige",
            },
            ".MuiAutocomplete-inputRoot": {
              backgroundColor: "beige",
            },
          },
        }}
      />

      {get(values, `${name}.operator.value`) !== OPERATORS.all.key.EXISTS && (
        <Fragment>
          {{
            [VARIABLE_TYPES.key.MCQ]: (
              <LbSmartSelect
                placeholder="Select value"
                required
                name={`${name}.value`}
                options={variableResponseOptions}
                disabled={
                  isEmpty(get(values, `${name}.operator`)) || isEmpty(get(values, `${name}.source`))
                }
                label="Variable value"
                multiple={
                  includes(
                    [
                      OPERATORS.all.key.IN,
                      OPERATORS.all.key.NOT_IN,
                      OPERATORS.all.key.CONTAINS,
                      OPERATORS.all.key.DOES_NOT_CONTAINS,
                    ],
                    get(values, `${name}.operator.value`)
                  ) || selectedSourceVar?.multiple
                }
                selectAll={selectedSourceVar.selectAll}
                inputProps={{
                  sx: {
                    "&.MuiInputBase-root": {
                      backgroundColor: "beige!important",
                    },
                    ".MuiAutocomplete-inputRoot": {
                      backgroundColor: "beige!important",
                    },
                  },
                }}
              />
            ),
            [VARIABLE_TYPES.key.NUMBER]: !includes(
              [OPERATORS.number.key.BETWEEN, OPERATORS.number.key.NOT_BETWEEN],
              get(values, `${name}.operator.value`)
            ) ? (
              <LbNumberField
                placeholder="00"
                required
                name={`${name}.value`}
                disabled={
                  isEmpty(get(values, `${name}.operator`)) || isEmpty(get(values, `${name}.source`))
                }
                label="Variable value"
                fullWidth
                sx={{ backgroundColor: "beige" }}
              />
            ) : (
              <Fragment>
                <LbNumberField
                  placeholder="00"
                  required
                  name={`${name}.value.0`}
                  disabled={
                    isEmpty(get(values, `${name}.operator`)) ||
                    isEmpty(get(values, `${name}.source`))
                  }
                  label="Variable low"
                  fullWidth
                  sx={{ backgroundColor: "beige" }}
                />
                <LbNumberField
                  placeholder="00"
                  required
                  name={`${name}.value.1`}
                  disabled={
                    isEmpty(get(values, `${name}.operator`)) ||
                    isEmpty(get(values, `${name}.source`))
                  }
                  label="Variable high"
                  fullWidth
                  sx={{ backgroundColor: "beige" }}
                />
              </Fragment>
            ),
          }[selectedSourceVar?.type] || (
            <LbTextField
              required
              name={`${name}.value`}
              label="Variable value"
              placeholder="Enter the variable value"
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "beige",
                },
              }}
              disabled={
                isEmpty(get(values, `${name}.operator`)) || isEmpty(get(values, `${name}.source`))
              }
            />
          )}
        </Fragment>
      )}

      <Box pt={1.45}>
        <NodeChip
          sx={{
            cursor: removeRule ? "pointer" : "not-allowed",
            pointerEvents: removeRule ? "all" : "none",
          }}
          label="Remove"
          icon={<LuGitCommit />}
          size="small"
          variant="outlined"
          color={removeRule ? "error" : "default"}
          onClick={removeRule}
        />
      </Box>
    </Stack>
  );
});

export default RuleStructure;
