import { jsonCargo } from "@lb/utils";
import { useFormikContext } from "formik";
import { cloneDeep, filter, find, findIndex } from "lodash";
import { Fragment, useState } from "react";
import AddEditVariable from "./AddEdit";
import { LbAlert, lbUid } from "@lb/frontend";
import { FiTrash2 } from "react-icons/fi";
import swal from "sweetalert";
import ImportVariables from "./Import";

const engine =
  (Component) =>
  ({ ...props }) => {
    const { values, setFieldValue } = useFormikContext();

    const [addEditDrawer, setAddEditDrawer] = useState(false);
    const [importDrawer, setImportDrawer] = useState(false);

    const openEditDrawer = (name) => {
      setAddEditDrawer(jsonCargo.store(find(values?.variables, { name })));
    };

    const handleSubmit = (variableData) => {
      let variableIndex = findIndex(values?.variables, { name: variableData?.name });

      if (variableIndex >= 0 || Boolean(variableData?.name)) {
        setFieldValue(`variables[${variableIndex}]`, variableData);
      } else {
        setFieldValue("variables", [
          ...values?.variables,
          {
            ...variableData,
            name: lbUid(),
          },
        ]);
      }

      setAddEditDrawer(false);
    };

    const duplicateVariable = (variableName) => {
      const variableIndex = findIndex(values.variables, { name: variableName });
      let duplicate = cloneDeep(values?.variables[variableIndex]);
      duplicate.label = `Copy of ${duplicate.label}`;
      duplicate.name = lbUid();

      let variablesListClone = cloneDeep(values.variables);
      variablesListClone.splice(variableIndex + 1, 0, duplicate);

      setFieldValue("variables", variablesListClone);
    };

    const removeVariable = (variableName) => {
      let variableLabel = find(values?.variables, { name: variableName })?.label;

      LbAlert({
        title: "Warning",
        buttons: ["Cancel", { text: "Confirm", closeModal: false }],
        message: `Confirm to delete? Deleting variable: ${variableLabel}`,
        danger: true,
        icon: FiTrash2,
      }).then(async (willDelete) => {
        if (willDelete) {
          setFieldValue(
            "variables",
            filter(values.variables, (variable) => variable.name !== variableName)
          );
        }
        swal.close();
      });
    };

    return (
      <Fragment>
        <ImportVariables open={importDrawer} handleClose={() => setImportDrawer(false)} />
        <AddEditVariable
          open={addEditDrawer}
          handleClose={() => setAddEditDrawer(false)}
          handleSubmit={handleSubmit}
        />
        <Component
          {...props}
          {...{
            variables: values.variables,
            setAddEditDrawer,
            openEditDrawer,
            duplicateVariable,
            removeVariable,
            setImportDrawer,
          }}
        />
      </Fragment>
    );
  };

export default engine;
